import LabeledText from '@/gf/components/LabeledText'
import SidebarProfileView from '@/gf/components/Layout/SidebarProfileView'
import EnabledBadge from '@/gf/components/MachineOrg/EnabledBadge'
import OwnedBadge from '@/gf/components/MachineOrg/OwnedBadge'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'

interface Props {
  machineDetails: OrgMachineDetails
  onEditClick: () => void
}

const OrgMachineView: React.FC<Props> = ({ machineDetails, onEditClick }) => {
  const orgMachine = machineDetails.data?.orgMachine

  return (
    <SidebarProfileView
      onEditClick={onEditClick}
      title={`${orgMachine?.machine.make} ${orgMachine?.machine.model}`}
      detail={`Unit #: ${orgMachine?.name}`}
      imageUrl={orgMachine?.machine?.logoUrl}
      className="w-full md:w-64"
    >
      <LabeledText label="Year" value={orgMachine?.machine.year} />
      <LabeledText label="Make" value={orgMachine?.machine.make} />
      <LabeledText label="Model" value={orgMachine?.machine.model} />
      <LabeledText label="Serial Number" value={orgMachine?.serialNumber} />
      {orgMachine?.description && (
        <LabeledText label="Description" value={orgMachine?.description} />
      )}
      <div className="flex flex-col gap-y-3 items-start">
        <OwnedBadge owned={orgMachine?.owned || false} />
        <EnabledBadge enabled={orgMachine?.enabled || false} />
      </div>
    </SidebarProfileView>
  )
}

export default OrgMachineView
