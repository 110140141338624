import classNames from 'classnames'
import { ReactNode } from 'react'
import { DateTime } from 'luxon'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { useReportingQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { useCommonReportingQueryParamString } from './Reporting/useReportingFormQueryParams'

import { LinkTabs } from '@/gf/components/next/Tabs'
import Frame from '../components/Frame'
import ReportsPreview from '../components/ReportsPreview'
import Cost from './Reporting/Cost'
import DealerScorecardNext from './Reporting/DealerScorecardNext'
import Overview from './Reporting/OverviewNext'
import Performance from './Reporting/Performance'
import RefetchButton from './Reporting/RefetchButton'
import TeamReport from './Reporting/TeamReport'
import Time from './Reporting/Time'

const addQueryParamToUrl = (url: string, queryParam: string) => {
  if (!queryParam) return url
  const splitHash = url.split('#')
  splitHash.splice(0, 1, `${splitHash[0]}?${queryParam}`)
  return splitHash.join('#')
}

const Page = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={classNames('p-12 min-w-full flex grow bg-gray-50', className)}>{children}</div>
)

const tabs = [
  { name: 'Summary', path: 'overview' },
  { name: 'Parts Spend', path: 'cost' },
  { name: 'Time', path: 'time' },
  { name: 'Performance', path: 'performance' },
  { name: 'Vendors', path: 'vendor-scorecard' },
  { name: 'Team', path: 'team' },
]
const getTabName = (pathname: string) => tabs.find(({ path }) => pathname.endsWith(path))?.name

const Reporting = () => {
  const { pathname } = useLocation()
  const commonQueryParamString = useCommonReportingQueryParamString()
  const { organization, orgId } = useSession()
  const client = useGqlClient()
  const { org } = useReportingQuery({ variables: { orgId }, client }).data || {}

  const hasEnoughReportData = (org?.stats.requestCount ?? 0) > 0

  if (!org) return null

  const isProPlan = org.features.viewReporting
  const inTrialPeriod = organization.buyerDashboardAccess === true
  const trialExpirationDate = organization.buyerDashboardAccessExpiresAt ?? undefined
  const expiresAt = DateTime.fromISO(`${trialExpirationDate}`).endOf('day')
  const { days } = expiresAt.diff(DateTime.now().endOf('day'), ['days'])
  const totalDays = days > 0.1 ? Math.ceil(days) : Math.round(days)
  const trialExpired = totalDays <= 0
  const hasExpiredTrial = inTrialPeriod && trialExpired

  return (
    <Frame>
      {org && (!org.features.viewReporting || !hasEnoughReportData) ? (
        <Page className="flex flex-col !bg-gray-100">
          <div className="w-full max-w-[70rem]">
            <ReportsPreview
              inTrialPeriod={inTrialPeriod}
              hasExpiredTrial={hasExpiredTrial}
              isProPlan={isProPlan}
              hasEnoughReportData={hasEnoughReportData}
            />
          </div>
        </Page>
      ) : (
        <Page className="flex flex-col gap-y-6">
          <div className="flex flex-row gap-x-8 items-center">
            <LinkTabs
              tabs={tabs.map((tab) => ({
                ...tab,
                to: addQueryParamToUrl(tab.path, commonQueryParamString),
              }))}
              selectedTabName={getTabName(pathname) ?? tabs[0].name}
            />
            <div className="flex grow" />
            <RefetchButton refetch={() => client.refetchQueries({ include: 'active' })} />
          </div>

          <Routes>
            <Route path="" element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<Overview />} />
            <Route path="time" element={<Time />} />
            <Route path="cost" element={<Cost />} />
            <Route path="performance" element={<Performance />} />
            <Route path="vendor-scorecard" element={<DealerScorecardNext />} />
            <Route path="team" element={<TeamReport />} />
          </Routes>
        </Page>
      )}
    </Frame>
  )
}

export default Reporting
