import { useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { SortOrder, useHomeNextQuery } from '@/buyers/_gen/gql'
import Frame from '@/buyers/components/Frame'
import InviteModal from './Home/InviteModal'
import OnboardingTasks from './Home/OnboardingTasks'
import RequestFlowCallout from './Home/RequestFlowCallout'
import ReportsSection from './Home/ReportsSection'
import WelcomeMessage from './Home/WelcomeMessage'
import QuickMetrics from './Home/QuickMetrics'
import useMetrics from './Reporting/useMetrics'
import duration from './Home/ReportsSection/duration'
import { MIN_REQUESTS_FOR_REPORTING } from '../modules/Reporting'
import Ghost from '@/gf/components/Ghost'
import RequestSteps from './Home/RequestSteps'
import CreateFirstRequest from './Home/CreateFirstRequest'
import Box from '@/gf/components/Box'

const Loading = () => (
  <div className="p-4 lg:p-8 xl:p-12 space-y-4 lg:space-y-6 xl:space-y-8 max-w-[73.875rem]">
    <div>
      <Ghost className="block w-2/3 h-8 bg-gray-50" />
      <Ghost className="block w-3/5 h-6 bg-gray-50 mt-2" />
    </div>

    <Box className="shadow-base rounded-xl bg-gray-50 p-6">
      <Ghost className="block w-40 h-6 " />
      <Ghost className="block w-4/5 h-4  mt-2" />

      <Ghost className="block w-3/5 h-20 mt-6" />
    </Box>

    <Box className="shadow-base rounded-xl bg-gray-50 p-6 flex overflow-hidden space-x-4">
      <Ghost className="block w-56 h-75 rounded-lg flex-shrink-0" />
      <Ghost className="block w-56 h-75 rounded-lg flex-shrink-0" />
      <Ghost className="block w-56 h-75 rounded-lg flex-shrink-0" />
      <Ghost className="block w-56 h-75 rounded-lg flex-shrink-0" />
      <Ghost className="block w-56 h-75 rounded-lg flex-shrink-0" />
    </Box>
  </div>
)

const Home = () => {
  const { organization, user } = useSession()
  const [inviteModalOpen, inviteModalToggler] = useToggle()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const [_, msgr] = useMsgs()
  const contentRef = useRef<HTMLElement>(null)

  const queryData = useHomeNextQuery({
    variables: {
      orgId: organization.id,
      userId: user.id,
    },
    client: useGqlClient(),
  }).data

  const isProPlan = useMemo(
    () =>
      queryData
        ? !!organization.buyerDashboardAccess || !!queryData.planSubscription?.id
        : !!organization.buyerDashboardAccess,
    [queryData, organization]
  )

  const inTrialPeriod = organization.buyerDashboardAccess === true
  const trialExpirationDate = organization.buyerDashboardAccessExpiresAt ?? undefined
  const expiresAt = DateTime.fromISO(`${trialExpirationDate}`).endOf('day')
  const { days } = expiresAt.diff(DateTime.now().endOf('day'), ['days'])
  const totalDays = days > 0.1 ? Math.ceil(days) : Math.round(days)
  const trialExpired = totalDays <= 0
  const hasExpiredTrial = inTrialPeriod && trialExpired

  const org = queryData?.org
  const pendingInvites = queryData?.user.pendingInvites

  const onInviteModalClosed = () => {
    inviteModalToggler.off()

    if (org && !org.setupStartedAt) navigate('/setup')
  }

  useEffect(() => {
    if (org && pendingInvites) {
      if (pendingInvites.length > 0) inviteModalToggler.on()
      else if (!org.setupStartedAt) navigate('/setup')
    }
  }, [org, pendingInvites])

  useEffect(() => {
    if (queryParams.get('email-confirmed') !== null) {
      queryParams.delete('email-confirmed')
      setQueryParams(queryParams)
      msgr.add('Email confirmed.', 'positive')
    }
  }, [])

  const form = {
    ...duration,
    urgentRequestsOnly: false,
    tab: 'Machine',
    metric: '',
  }

  const sortBy = { field: 'total.sum', order: SortOrder.Desc }
  const requestsSortBy = {
    field: 'total',
    order: SortOrder.Desc,
  }

  const metrics = useMetrics({
    form,
    selectedValues: [],
    selectedDate: undefined,
    sortBy,
    requestsSortBy,
    storeFilter: ({ store, total }) => !!store && !!total,
    creatorFilter: ({ creator, total }) => !!creator && !!total,
    purchaserFilter: ({ assignedUser, total }) => !!assignedUser && !!total,
    machineFilter: ({ orgMachine, total }) => !!orgMachine && !!total,
    categoryFilter: ({ category, total }) => !!category.id && !!total,
    urgencyFilter: ({ urgency, total }) => !!urgency && !!total,
    requestFilter: ({ total }) => total !== null,
    getChartValue: ({ total }) => total?.sum,
  })

  const hasEnoughReportData = (queryData?.org?.stats.requestCount ?? 0) > MIN_REQUESTS_FOR_REPORTING
  const isInitialSetupComplete = !!queryData?.org?.setupCompletedAt

  return (
    <Frame contentRef={contentRef}>
      {pendingInvites && (
        <InviteModal
          pendingInvites={pendingInvites}
          open={inviteModalOpen}
          onClose={onInviteModalClosed}
        />
      )}

      {queryData === undefined ? (
        <Loading />
      ) : (
        <>
          {org?.stats.requestCount === 0 ? (
            <div className="p-4 lg:p-8 xl:p-12 space-y-4 lg:space-y-6 xl:space-y-8  max-w-[73.875rem]">
              {!isInitialSetupComplete && (
                <>
                  <WelcomeMessage org={queryData.org ?? null} />

                  <OnboardingTasks isInitialSetupComplete={false} />
                </>
              )}

              <CreateFirstRequest containerRef={contentRef} isProPlan={isProPlan} />

              <RequestSteps containerRef={contentRef} />
            </div>
          ) : (
            <>
              {/* Need a bunch of extra divs to fix the scroll bug https://github.com/GearFlowDev/gf_platform/pull/6085 */}
              <div className="flex flex-col grow">
                <div className="flex flex-col grow overflow-x-hidden">
                  <div className="p-4 lg:p-8 xl:p-12 space-y-4 lg:space-y-6 xl:space-y-8  max-w-[73.875rem] flex flex-col grow">
                    <WelcomeMessage org={queryData.org ?? null} />

                    <OnboardingTasks isInitialSetupComplete={isInitialSetupComplete} />

                    {isProPlan && !hasExpiredTrial && hasEnoughReportData && (
                      <QuickMetrics metrics={metrics} />
                    )}

                    {!hasEnoughReportData && <RequestFlowCallout />}

                    <ReportsSection
                      inTrialPeriod={inTrialPeriod}
                      hasEnoughReportData={hasEnoughReportData}
                      hasExpiredTrial={hasExpiredTrial}
                      isProPlan={isProPlan}
                      metrics={metrics}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Frame>
  )
}

export default Home
