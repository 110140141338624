import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PaperAirplaneIcon,
  ChatAlt2Icon,
  ArrowsExpandIcon,
  PencilAltIcon,
  CheckIcon,
  ClipboardIcon,
  XIcon,
  DocumentTextIcon,
  DocumentDuplicateIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/outline'

import useToggle from '@/gf/hooks/useToggle'
import {
  RequestForQuoteStep,
  RequestsQuery,
  PaymentMethod,
  InventoryFulfillmentMatch,
} from '@/buyers/_gen/gql'

import MultiButton, { MultiButtonAction, MultiButtonLink } from '@/gf/components/MultiButton'
import Modal from '@/gf/components/ModalNext'
import MarkReceivedModal from '@/buyers/pages/StoreOrder/Order/MarkReceivedModal'

type User = RequestsQuery['user']
type Request = RequestsQuery['searchRequests']['requests'][number]

const Actions = ({
  user,
  rfq,
  onMarkedAsReceived,
}: {
  user: User
  rfq: Request
  onMarkedAsReceived: () => void
}) => {
  const navigate = useNavigate()
  const [markReceivedModalOpen, markReceivedModalToggle] = useToggle()
  const [callToQuoteModalOpen, callToQuoteModalToggle] = useToggle()

  const [storeOrderIdForMarkReceived, setStoreOrderIdForMarkReceived] = useState<string | null>(
    null
  )

  const matchesInternalInventoryItems = rfq.inventoryMatch !== InventoryFulfillmentMatch.None

  const approveAction = {
    display: 'Approve',
    icon: PaperAirplaneIcon,
    iconClassName: 'rotate-90',
    description: 'Review and send the Request.',
    to: matchesInternalInventoryItems
      ? `/rfqs/${rfq.id}/approve/part-details`
      : rfq.resumeDealer && rfq.vendorLinks.length !== 0
      ? `/rfqs/${rfq.id}/approve/review`
      : rfq.resumeDealer
      ? `/rfqs/${rfq.id}/approve/dealers`
      : `/rfqs/${rfq.id}/approve/details`,
  }

  const editAction = {
    display: 'Edit',
    icon: PencilAltIcon,
    description: 'Edit the Request before sending it.',
    to: `/rfqs/${rfq.id}/approve/details`,
  }

  const msgAction = {
    display: 'Message',
    icon: ChatAlt2Icon,
    description:
      'Send your Vendor a message with an SMS and email notification (depending on their preferences).',
    to: `/rfqs/${rfq.id}/messages`,
  }

  const markAsReceivedActions = () => {
    const storeOrders = rfq.storeOrders.filter(
      (so) =>
        (so.state === 'RECEIVED' && so.paymentMethod === PaymentMethod.Direct) ||
        so.state === 'READY_FOR_PICKUP'
    )

    return storeOrders.map((so) => {
      const display =
        storeOrders.length > 1 ? `Mark Received | Order ${so.shortId}` : 'Mark Received'

      return {
        display,
        icon: CheckIcon,
        description: 'Select this option if you have received all items in this order.',
        onClick: () => {
          setStoreOrderIdForMarkReceived(so.id)
          markReceivedModalToggle.on()
        },
      }
    })
  }

  // Can only enter quotes for Vendors who have not already placed a Quote
  const callToQuoteVendors = rfq.vendorLinks.filter(
    ({ vendor }) => !rfq.storeOrders.some((storeOrder) => storeOrder.storeId === vendor.storeId)
  )

  const callToQuoteAction =
    callToQuoteVendors.length > 0
      ? {
          display: 'Enter Quote',
          icon: DocumentTextIcon,
          description:
            'Call your Vendor and enter the quote information here. Or enter your order information from an OEM portal.',
          ...(callToQuoteVendors.length === 1
            ? { to: `/rfqs/${rfq.id}/vendors/${rfq.vendorLinks[0].vendor.id}/create-quote` }
            : { onClick: callToQuoteModalToggle.on }),
        }
      : undefined

  const srcAltOptsAction = {
    display: 'Source Alternative Options',
    icon: ArrowsExpandIcon,
    description: 'Send your Request to alternative Vendors.',
    to: `/rfqs/${rfq.id}/add-vendors`,
  }

  const actions = (() => {
    const result: (MultiButtonAction | MultiButtonLink)[] = []

    if (rfq.step === RequestForQuoteStep.Inbound && user.can.approveRequests)
      result.push(approveAction)

    if (
      rfq.step === RequestForQuoteStep.Inbound &&
      user.can.approveRequests &&
      !rfq.createdByTutorial
    )
      result.push(editAction)

    if (rfq.step !== RequestForQuoteStep.Inbound) result.push(msgAction)

    if ([RequestForQuoteStep.PoSent, RequestForQuoteStep.Fulfilling].includes(rfq.step))
      result.concat(markAsReceivedActions())

    if (
      callToQuoteAction &&
      [RequestForQuoteStep.Submitted, RequestForQuoteStep.Quoted].includes(rfq.step) &&
      !rfq.createdByTutorial
    )
      result.push(callToQuoteAction)

    if (
      rfq.step === RequestForQuoteStep.Submitted &&
      rfq.vendorLinks.length > 0 &&
      !rfq.createdByTutorial
    )
      result.push(srcAltOptsAction)

    if (
      [
        RequestForQuoteStep.Inbound,
        RequestForQuoteStep.Submitted,
        RequestForQuoteStep.Quoted,
      ].includes(rfq.step) &&
      user.can.approveRequests &&
      !rfq.createdByTutorial
    ) {
      const inventoryAction = {
        display: 'In Inventory',
        icon: ClipboardIcon,
        description: 'Fulfill this request from parts you have in stock.',
        to: `/rfqs/${rfq.id}/fulfill-from-inventory`,
      }

      if (matchesInternalInventoryItems) {
        result.unshift(inventoryAction)
      } else {
        result.push(inventoryAction)
      }
    }

    if (rfq.step === RequestForQuoteStep.Inbound && !rfq.createdByTutorial)
      result.push({
        display: 'Order Manually',
        icon: ClipboardCopyIcon,
        description: 'Order items outside of Gearflow.',
        to: `/rfqs/${rfq.id}/order-manually`,
      })

    if (!rfq.createdByTutorial)
      result.push({
        display: 'Copy Request',
        icon: DocumentDuplicateIcon,
        description: 'Start a new request by copying over the information from this request.',
        to: `/rfqs/${rfq.id}/copy`,
      })

    return result
  })()

  return (
    <>
      <MultiButton align="left" actions={actions} />

      <MarkReceivedModal
        open={markReceivedModalOpen}
        close={markReceivedModalToggle.off}
        storeOrderId={storeOrderIdForMarkReceived || ''}
        onSuccess={() => {
          markReceivedModalToggle.off()
          setStoreOrderIdForMarkReceived(null)
          onMarkedAsReceived()
        }}
      />

      <Modal open={!!callToQuoteModalOpen} onClose={callToQuoteModalToggle.off}>
        <div className="flex flex-col">
          <div className="px-6 py-3 flex flex-row justify-between items-center text-gray-900 text-lg border-b border-gray-100">
            <div className="flex flex-row items-center gap-x-1">
              <DocumentTextIcon className="w-6 h-6 inline-flex shrink-0" />
              <span>Enter Quote for Vendor</span>
            </div>

            <button onClick={callToQuoteModalToggle.off} type="button">
              <XIcon className="w-5 h-5" />
            </button>
          </div>

          <div className="flex flex-col justify-start items-stretch divide-y divide-gray-100">
            {callToQuoteVendors.map(({ vendor }) => (
              <button
                key={vendor.id}
                className="px-6 py-3 text-left text-sm text-gray-700 hover:text-gray-900 bg-white hover:bg-gray-100"
                onClick={() => navigate(`/rfqs/${rfq.id}/vendors/${vendor.id}/create-quote`)}
                type="button"
              >
                {vendor.name}
              </button>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Actions
