import { FrameQuery } from '@/buyers/_gen/gql'
import useFrame from '@/buyers/hooks/useFrame'
import QueryString from '@/buyers/modules/QueryString'
import Ghost from '@/gf/components/Ghost'
import SlideOver from '@/gf/components/SlideOver'
import { ClipboardListIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'

const ButtonOrLink = ({ onClick, to, children, className }) =>
  onClick ? (
    <button type="button" className={className}>
      {children}
    </button>
  ) : to ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : null

const TodoCard = ({
  title,
  to,
  onClick,
  children,
  urgent,
}: {
  title: string
  to?: string
  onClick?: () => void
  children: React.ReactNode
  urgent?: boolean | null | undefined
}) => (
  <li className="list-none">
    <ButtonOrLink to={to} onClick={onClick} className="flex p-2 border-b">
      <div
        className={classNames(
          'shrink-0 w-[0.375rem] rounded-lg',
          urgent
            ? 'bg-red-500'
            : urgent === null
            ? 'bg-blue-500'
            : urgent === undefined
            ? 'bg-yellow-400'
            : 'bg-green-500'
        )}
      />

      <div className="flex-1 px-5 py-3 space-y-2">
        <h2 className="text-base font-medium">{title}</h2>
        <div className="text-sm prose">{children}</div>
      </div>
    </ButtonOrLink>
  </li>
)

const TodoCardGhost = () => (
  <li className="border-b flex p-2">
    <div className="shrink-0 w-[0.375rem] rounded-lg bg-gray-200" />

    <div className="flex-1 px-5 py-3 space-y-2">
      <Ghost className="block h-5 w-2/3" />
      <Ghost className="block h-4 w-full mt-2" />
      <Ghost className="block h-4 w-2/4 mt-0.5" />
    </div>
  </li>
)

const Todo = ({ todo }: { todo: FrameQuery['allTodos'][number] }) => {
  if (todo.type === 'request_needs_approval')
    return (
      <TodoCard
        title="Request needing approval"
        to={`/rfqs${QueryString.inlineRequestsNeedApproval()}`}
        urgent={todo.urgent}
      >
        You have {pluralize('request', todo.count, true)} needing approval.
      </TodoCard>
    )

  if (todo.type === 'rfq_needs_approval')
    return (
      <TodoCard
        title="Quote needing approval"
        to={`/rfqs${QueryString.inlineQuotesNeedApproval()}`}
        urgent={todo.urgent}
      >
        You have {pluralize('request', todo.count, true)} with quotes that need approval.
      </TodoCard>
    )

  if (todo.type === 'needs_attention')
    return (
      <TodoCard
        title="Check Status"
        to={`/orders${QueryString.orderNeedsAttn()}`}
        urgent={todo.urgent}
      >
        You have {pluralize('order', todo.count, true)} that have been processing for a while, check
        in on the status of the order or close them out.
      </TodoCard>
    )

  if (todo.type === 'mark_as_received')
    return (
      <TodoCard
        title="Mark Received"
        to={`/orders${QueryString.orderMarkReceived()}`}
        urgent={todo.urgent}
      >
        You have {pluralize('order', todo.count, true)} that are ready. If you have received them,
        mark these orders as received.
      </TodoCard>
    )

  if (todo.type === 'join_requests')
    return (
      <TodoCard title="Join Requests" to="/settings/users" urgent={todo.urgent}>
        You have {pluralize('user', todo.count, true)} requesting to join your account. Your
        approval is needed.
      </TodoCard>
    )

  return null
}

const TasksPanel = ({ open, onClose }) => {
  const todos = useFrame()?.todos

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title={`Your Tasks ${todos && todos.length > 0 ? `(${todos.length})` : ''}`}
      titleIcon={<ClipboardListIcon className="h-6 w-6" />}
      size="sm"
      theme="white"
    >
      {todos === undefined ? (
        <>
          <TodoCardGhost />
        </>
      ) : todos.length === 0 ? (
        <p className="p-4">Great job, All tasks have been completed!</p>
      ) : (
        todos.map((t) => <Todo todo={t} key={t.type} />)
      )}
    </SlideOver>
  )
}

export default TasksPanel
