import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import { PencilIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import EngineSpecsForm from './Specs/EngineSpecsForm'
import EngineSpecsGhost from './Specs/EngineSpecsGhost'
import EngineSpecsView from './Specs/EngineSpecsView'
import Images from './Specs/Images'

interface Props {
  machineDetails: OrgMachineDetails
}

const Specs: React.FC<Props> = ({ machineDetails }) => {
  const orgMachine = machineDetails.data?.orgMachine
  const [editing, setEditing] = useState(false)

  const cancelEditing = () => setEditing(false)

  if (machineDetails.loading) {
    return <EngineSpecsGhost />
  }

  return (
    <>
      <h3 className="flex items-center font-black text-xl text-slate-600 pb-4">
        Engine Information
        {!editing && (
          <button
            type="button"
            className="rounded-full inline-flex items-center justify-center h-8 w-8 hover:bg-slate-50 ml-2"
            onClick={() => setEditing(true)}
          >
            <PencilIcon className="h-5 text-zinc-400" />
          </button>
        )}
      </h3>
      {editing ? (
        <EngineSpecsForm
          machineDetails={machineDetails}
          onAfterSave={cancelEditing}
          onCancel={cancelEditing}
        />
      ) : (
        <EngineSpecsView machineDetails={machineDetails} />
      )}

      {orgMachine && <Images orgMachineId={orgMachine.id} />}
    </>
  )
}

export default Specs
