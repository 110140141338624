import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import TextField from '@/gf/components/TextField'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import Field from '@/gf/components/Field'

import type { AdminUserFormReducer } from '../../../../hooks/useAddUserForm'

const Details = ({
  form: { fields, errors },
  updateFields,
  validate,
  cancel,
  navigateToStepIndex,
}: AdminUserFormReducer & { cancel: () => void; navigateToStepIndex: (index: number) => void }) => {
  const next = () => validate('details').then(() => navigateToStepIndex(1))

  return (
    <Card>
      <Card.Section>
        <div className="space-y-4">
          <TextField
            label="Name"
            value={fields.name}
            setValue={(name) => updateFields({ name })}
            errorText={errors.name}
          />
          <TextField
            label="Email"
            value={fields.email}
            setValue={(email) => updateFields({ email })}
            errorText={errors.email}
            disabled={!!fields.inviteId}
          />
          <Field label="Phone number" errorText={errors.phoneNumber}>
            <PhoneInput
              value={fields.phoneNumber}
              onChange={(phoneNumber) => updateFields({ phoneNumber })}
            />
          </Field>
          <TextField
            label="Title (optional)"
            value={fields.title}
            setValue={(title) => updateFields({ title })}
            errorText={errors.title}
          />
        </div>
      </Card.Section>
      <Card.Section>
        <div className="flex gap-6 justify-end">
          <Action.T onClick={cancel}>
            <span className="text-sm">Cancel</span>
          </Action.T>

          <Action.P onClick={next}>Next</Action.P>
        </div>
      </Card.Section>
    </Card>
  )
}

export default Details
