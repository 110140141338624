import { useOrganizationUserMetricsQuery, useStoreRequestMetricsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Reporting from '@/buyers/modules/Reporting'
import Ghost from '@/gf/components/Ghost'
import RedAlert from '@/gf/components/RedAlert'
import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import Money from '@/gf/modules/Money'
import TimeM from '@/gf/modules/Time'
import { Transition } from '@headlessui/react'
import { LightBulbIcon } from '@heroicons/react/outline'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChartBarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ScaleIcon,
  UserGroupIcon,
} from '@heroicons/react/solid'
import { map, nth, orderBy } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getOrgMachineName } from './OrgMachine'
import ScorecardContainer from './ScorecardContainer'
import { NO_DATA_VALUE, ScorecardData, Unit, defaultFormatValue } from './Scorecards'
import StandardColumns from './StandardColumns'
import useReportingFormQueryParams, {
  useCommonReportingQueryParamString,
} from './useReportingFormQueryParams'
import { encodeInsightQueryParamString } from './useReportingInsightQueryParam'

const formatMoneyValue = (value: number) =>
  Money.format(Money.fromInt(value, 'USD'), { maximumFractionDigits: 0 })

const Overview = () => {
  const { orgId, featureFlags, organization } = useSession()
  const client = useGqlClient()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [showScrollShadowLeft, setShowScrollShadowLeft] = useState(false)
  const [showScrollShadowRight, setShowScrollShadowRight] = useState(false)

  const { form, updateForm } = useReportingFormQueryParams({
    defaultDurationId,
    defaultDurationDates,
  })
  const commonQueryParamString = useCommonReportingQueryParamString()

  const commonStoreRequestMetricsVariables = {
    filter: JSON.stringify([[]]),
  }

  const prevDurationEnd = form.durationStart.minus({ day: 1 })
  const prevDurationStart = prevDurationEnd.minus(form.durationEnd.diff(form.durationStart))

  const { data, error } = useStoreRequestMetricsQuery({
    client,
    variables: {
      orgId,
      ...commonStoreRequestMetricsVariables,
      fromDate: form.durationStart.toISODate(),
      toDate: form.durationEnd.toISODate(),
    },
  })

  const { data: prevData, error: prevError } = useStoreRequestMetricsQuery({
    client,
    variables: {
      orgId,
      ...commonStoreRequestMetricsVariables,
      fromDate: prevDurationStart.toISODate(),
      toDate: prevDurationEnd.toISODate(),
    },
  })

  const { data: organizationUserData, error: organizationUserDataError } =
    useOrganizationUserMetricsQuery({
      client,
      variables: {
        ...commonStoreRequestMetricsVariables,
        fromDate: form.durationStart.toISODate(),
        toDate: form.durationEnd.toISODate(),
        orgId: organization.id,
      },
    })
  const organizationUserOrderedByRequests = organizationUserData
    ? orderBy(organizationUserData.organizationUserMetrics, ['requestsCreated'], ['desc'])
    : undefined
  const organizationUserMostRequests = nth(organizationUserOrderedByRequests, 0)
  const organizationUserOrderedByQuotesApproved = organizationUserData
    ? orderBy(organizationUserData.organizationUserMetrics, ['quotesApproved'], ['desc'])
    : undefined
  const organizationUserMostQuotesApproved = nth(organizationUserOrderedByQuotesApproved, 0)

  const requestMetrics = data ? Reporting.getRequestMetrics(data.storeRequestMetrics) : undefined
  const aggregatedRequestMetrics = requestMetrics
    ? Reporting.aggregateFirstDegreeMetrics(map(requestMetrics))
    : undefined
  const prevRequestMetrics = prevData
    ? Reporting.getRequestMetrics(prevData.storeRequestMetrics)
    : undefined
  const prevAggregatedRequestMetrics = prevRequestMetrics
    ? Reporting.aggregateFirstDegreeMetrics(map(prevRequestMetrics))
    : undefined

  const storeMetrics = data ? Reporting.getStoreMetrics(data.storeRequestMetrics) : undefined
  const storeMetricsOrderedByRequestsCount = storeMetrics
    ? orderBy(
        map(storeMetrics).filter(
          ({ store, orderFulfillmentDuration }) =>
            store !== null && orderFulfillmentDuration !== null
        ),
        ['orderFulfillmentDuration.count'],
        ['desc']
      )
    : undefined
  const storeMetricMostRequests = nth(storeMetricsOrderedByRequestsCount, 0)

  const machineMetrics = data ? Reporting.getMachineMetrics(map(requestMetrics)) : undefined
  const machineMetricsOrderedByCost = machineMetrics
    ? orderBy(
        map(machineMetrics).filter(({ total }) => total !== null),
        ['total.sum'],
        ['desc']
      )
    : undefined
  const machineMetricMostExpensive = nth(machineMetricsOrderedByCost, 0)

  const scrollListener = useCallback(() => {
    if (scrollRef.current) {
      if (scrollRef.current?.scrollLeft === 0) {
        setShowScrollShadowLeft(false)
      } else {
        setShowScrollShadowLeft(true)
      }

      if (
        scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
        scrollRef.current.scrollWidth
      ) {
        setShowScrollShadowRight(false)
      } else {
        setShowScrollShadowRight(true)
      }
    }
  }, [scrollRef, setShowScrollShadowLeft, setShowScrollShadowRight])

  useEffect(() => {
    // Initialize the shadows
    scrollListener()
  }, [data])

  return (
    <div className="flex flex-col gap-y-8">
      <DurationInput
        start={form.durationStart}
        end={form.durationEnd}
        durationId={form.durationId}
        onChange={({ start, end, durationId }) =>
          updateForm({ durationStart: start, durationEnd: end, durationId })
        }
        showDates
      />

      <div className="flex flex-col gap-y-4">
        {featureFlags.reportingInsights && (
          <div className="relative flex flex-col gap-y-4">
            <div id="insights" className="absolute -top-20" />
            <div className="flex flex-row gap-x-2 items-center">
              <LightBulbIcon className="w-6 h-6 text-gray-700" />
              <span className="text-xl text-gray-900">Insights</span>
            </div>

            {/* TODO: turn this into a reusable component or hook or something */}
            <div className="-mt-2 relative">
              <Transition
                show={showScrollShadowLeft}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-4 absolute top-0 bottom-0 left-0 z-20 opacity-5 bg-gradient-to-r from-black" />
                <button
                  className="absolute top-1/2 -translate-y-1/2 left-2 z-30 p-2 bg-white rounded-full shadow-lg border border-blue-50"
                  type="button"
                  onClick={() =>
                    scrollRef.current?.scrollBy({
                      left: -scrollRef.current.offsetWidth,
                      behavior: 'smooth',
                    })
                  }
                >
                  <ArrowLeftIcon className="text-blue-500 w-5 h-5 flex shrink-0" />
                </button>
              </Transition>
              <Transition
                show={showScrollShadowRight}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-4 absolute top-0 bottom-0 right-0 z-20 opacity-5 bg-gradient-to-l from-black" />
                <button
                  className="absolute top-1/2 -translate-y-1/2 right-2 z-30 p-2 bg-white rounded-full shadow-lg border border-blue-50"
                  type="button"
                  onClick={() =>
                    scrollRef.current?.scrollBy({
                      left: scrollRef.current.offsetWidth,
                      behavior: 'smooth',
                    })
                  }
                >
                  <ArrowRightIcon className="text-blue-500 w-5 h-5 flex shrink-0" />
                </button>
              </Transition>
              <div
                ref={scrollRef}
                className="py-2 w-full flex flex-row gap-x-3 overflow-x-scroll"
                onScroll={scrollListener}
              >
                {!requestMetrics ? (
                  <>
                    <Ghost className="w-64 h-32 flex shrink-0 bg-slate-200" />
                    <Ghost className="w-64 h-32 flex shrink-0 bg-slate-200" />
                    <Ghost className="w-64 h-32 flex shrink-0 bg-slate-200" />
                  </>
                ) : !machineMetricMostExpensive?.total ? (
                  <div className="px-4 pt-2 pb-4 flex text-base text-gray-500">
                    No insights found yet.
                  </div>
                ) : (
                  <>
                    {[0, 1, 2, 3, 4].map((i) =>
                      machineMetricMostExpensive.total ? (
                        <Link
                          key={i}
                          className="group max-w-xs flex shrink-0"
                          to={`/reporting/cost?${
                            commonQueryParamString ? `${commonQueryParamString}&` : ''
                          }${encodeInsightQueryParamString({
                            tab: 'Machine',
                            selectedValues: [
                              {
                                value: machineMetricMostExpensive.orgMachine.id,
                                display: getOrgMachineName(machineMetricMostExpensive.orgMachine),
                              },
                            ],
                          })}`}
                        >
                          <div>
                            <ScorecardContainer.Card>
                              <div className="px-4 pt-3 flex flex-col">
                                <div className="flex flex-row items-center gap-x-2">
                                  {/* TODO: make this {title} */}
                                  <div className="text-base leading-tight font-medium text-gray-700">
                                    Most Expensive Machine
                                  </div>
                                </div>
                                <div className="py-2 text-sm text-gray-500">
                                  {/* TODO: make this {children} */}
                                  <div>
                                    Spent{' '}
                                    {defaultFormatValue(
                                      Money.fromInt(machineMetricMostExpensive.total.sum, 'USD'),
                                      0
                                    )}{' '}
                                    on {getOrgMachineName(machineMetricMostExpensive.orgMachine)}
                                  </div>
                                </div>
                              </div>
                              <div className="px-4 py-2 flex justify-end bg-gray-50">
                                <span className="text-sm text-blue-500 group-hover:underline">
                                  View details
                                </span>
                              </div>
                            </ScorecardContainer.Card>
                          </div>
                        </Link>
                      ) : null
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="relative flex flex-col gap-y-4">
          {error || prevError ? (
            <RedAlert className="border border-red-200" title="Error, please contact support." />
          ) : (
            <div className="flex flex-col gap-y-4">
              <ScorecardContainer
                title="Parts Spend"
                SolidIcon={CurrencyDollarIcon}
                to={`/reporting/cost?${commonQueryParamString}`}
                content={[
                  <ScorecardData
                    name="Total Parts Spend"
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.total === null
                        ? null
                        : aggregatedRequestMetrics.total.sum
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.total === null
                        ? null
                        : prevAggregatedRequestMetrics.total.sum
                    }
                    formatValue={formatMoneyValue}
                    downIsGood
                  />,
                  <ScorecardData
                    name="Total Savings"
                    tooltip={StandardColumns.costSavingsTooltip}
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.costSavings === null
                        ? null
                        : aggregatedRequestMetrics.costSavings.sum
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.costSavings === null
                        ? null
                        : prevAggregatedRequestMetrics.costSavings.sum
                    }
                    formatValue={formatMoneyValue}
                  />,
                ]}
              />
              <ScorecardContainer
                title="Time"
                SolidIcon={ClockIcon}
                to={`/reporting/time?${commonQueryParamString}`}
                content={[
                  <ScorecardData
                    name={StandardColumns.requestLifespanTitle}
                    tooltip={StandardColumns.requestLifespanTooltip}
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.lifespanDuration === null
                        ? null
                        : aggregatedRequestMetrics.lifespanDuration.median
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.lifespanDuration === null
                        ? null
                        : prevAggregatedRequestMetrics.lifespanDuration.median
                    }
                    formatValue={TimeM.secondsToString}
                    downIsGood
                  />,
                  <ScorecardData
                    name="Total Lead Time Saved"
                    tooltip={StandardColumns.timeSavingsTooltip}
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.timeSavings === null
                        ? null
                        : aggregatedRequestMetrics.timeSavings.sum
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.timeSavings === null
                        ? null
                        : prevAggregatedRequestMetrics.timeSavings.sum
                    }
                    formatValue={TimeM.secondsToString}
                  />,
                ]}
              />
              <ScorecardContainer
                title="Performance"
                SolidIcon={ChartBarIcon}
                to={`/reporting/performance?${commonQueryParamString}`}
                content={[
                  <ScorecardData
                    name="Urgent Requests"
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.urgent === null
                        ? null
                        : aggregatedRequestMetrics.urgent.percent
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.urgent === null
                        ? null
                        : prevAggregatedRequestMetrics.urgent.percent
                    }
                    unit={Unit.Percent}
                    downIsGood
                  />,
                  <ScorecardData
                    name="Accurate Requests"
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.accurate === null
                        ? null
                        : aggregatedRequestMetrics.accurate.percent
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.accurate === null
                        ? null
                        : prevAggregatedRequestMetrics.accurate.percent
                    }
                    unit={Unit.Percent}
                  />,
                  <ScorecardData
                    name="Internal Fulfillment"
                    value={
                      typeof aggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : aggregatedRequestMetrics.internalFulfillment === null
                        ? null
                        : aggregatedRequestMetrics.internalFulfillment.percent
                    }
                    fromValue={
                      typeof prevAggregatedRequestMetrics === 'undefined'
                        ? undefined
                        : prevAggregatedRequestMetrics.internalFulfillment === null
                        ? null
                        : prevAggregatedRequestMetrics.internalFulfillment.percent
                    }
                    unit={Unit.Percent}
                  />,
                ]}
              />
              <div className="w-full py-2 flex justify-center">
                <div className="w-4/5 border-b border-gray-300" />
              </div>
              <ScorecardContainer
                title="Vendors"
                SolidIcon={ScaleIcon}
                to={`/reporting/vendor-scorecard?${commonQueryParamString}`}
                content={[
                  <div className="flex flex-col items-stretch gap-y-1">
                    <h5 className="text-xs lg:text-sm text-gray-900 font-medium text-left">
                      Most Requests
                    </h5>
                    <div className="flex flex-row flex-wrap justify-between items-end gap-x-4">
                      {typeof storeMetricsOrderedByRequestsCount === 'undefined' ? (
                        <Ghost className="w-48 h-8 inline-flex" />
                      ) : (
                        <span className="block text-xl lg:text-2xl text-gray-900 font-medium">
                          {!storeMetricMostRequests?.store
                            ? NO_DATA_VALUE
                            : storeMetricMostRequests.store.name}
                        </span>
                      )}
                      {typeof storeMetricsOrderedByRequestsCount === 'undefined' ? (
                        <Ghost className="w-20 h-7 inline-block" />
                      ) : !storeMetricMostRequests?.orderFulfillmentDuration ? null : (
                        <span className="text-sm text-gray-500 whitespace-nowrap">
                          {storeMetricMostRequests.orderFulfillmentDuration?.count} Requests
                        </span>
                      )}
                    </div>
                  </div>,
                ]}
              />
              <ScorecardContainer
                title="Team"
                SolidIcon={UserGroupIcon}
                to={`/reporting/team?${commonQueryParamString}`}
                content={
                  organizationUserDataError
                    ? [
                        <RedAlert
                          className="border border-red-200"
                          title="Error, please contact support."
                        />,
                      ]
                    : [
                        <div className="flex flex-col items-stretch gap-y-1">
                          <h5 className="text-xs lg:text-sm text-gray-900 font-medium text-left">
                            Most Requests Created
                          </h5>
                          <div className="flex flex-row flex-wrap justify-between items-end gap-x-4">
                            {typeof organizationUserMostRequests === 'undefined' ? (
                              <Ghost className="w-48 h-8 inline-flex" />
                            ) : (
                              <span className="block text-xl lg:text-2xl text-gray-900 font-medium">
                                {!organizationUserMostRequests.requestsCreated
                                  ? NO_DATA_VALUE
                                  : organizationUserMostRequests.user.name}
                              </span>
                            )}
                            {typeof organizationUserMostRequests === 'undefined' ? (
                              <Ghost className="w-20 h-7 inline-block" />
                            ) : !organizationUserMostRequests?.requestsCreated ? null : (
                              <span className="text-sm text-gray-500 whitespace-nowrap">
                                {organizationUserMostRequests.requestsCreated} Requests
                              </span>
                            )}
                          </div>
                        </div>,
                        <div className="flex flex-col items-stretch gap-y-1">
                          <h5 className="text-xs lg:text-sm text-gray-900 font-medium text-left">
                            Most Quotes Approved
                          </h5>
                          <div className="flex flex-row flex-wrap justify-between items-end gap-x-4">
                            {typeof organizationUserMostQuotesApproved === 'undefined' ? (
                              <Ghost className="w-48 h-8 inline-flex" />
                            ) : (
                              <span className="block text-xl lg:text-2xl text-gray-900 font-medium">
                                {!organizationUserMostQuotesApproved.quotesApproved
                                  ? NO_DATA_VALUE
                                  : organizationUserMostQuotesApproved.user.name}
                              </span>
                            )}
                            {typeof organizationUserMostQuotesApproved === 'undefined' ? (
                              <Ghost className="w-20 h-7 inline-block" />
                            ) : !organizationUserMostQuotesApproved?.quotesApproved ? null : (
                              <span className="text-sm text-gray-500 whitespace-nowrap">
                                {organizationUserMostQuotesApproved.quotesApproved} Quotes
                              </span>
                            )}
                          </div>
                        </div>,
                      ]
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Overview
