import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import Modal from '@/gf/components/ModalNext'
import SearchInput from '@/gf/components/SearchInput'
import { ModalSize } from '@/types'
import debounce from 'lodash/debounce'
import { useMemo, useRef, useState } from 'react'
import { useSelectShippingLocationModalQuery } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'

const ListItem = ({ title, subtitle, to }) => (
  <li className="flex items-center p-4 gap-x-4 border shadow-base rounded-lg">
    <div className="flex-grow truncate">
      <h5 className="font-medium text-lg text-ellipsis truncate">{title}</h5>
      <p className="text-sm">{subtitle}</p>
    </div>
    <Link.P color="blue" to={to} className="flex-shrink-0">
      Add Vendors
    </Link.P>
  </li>
)

const SelectShippingLocationModal = ({
  open,
  onClose,
  title,
  subtitle,
  onSaveAndExit,
}: {
  open: boolean
  onClose: () => void
  title: string
  subtitle?: string
  onSaveAndExit?: () => void
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [search, setSearch] = useState('')
  const { data, loading } = useSelectShippingLocationModalQuery({
    client,
    variables: { orgId, search },
  })

  const onSearchChangedDebounced = useRef(debounce((value) => setSearch(value), 500)).current

  const locations = useMemo(() => {
    const defaultLocation = data?.defaultShippingLocation ?? null
    const others = data?.org?.locations.filter((l) => !l.defaultLocation) ?? []

    return defaultLocation ? [defaultLocation, ...others] : others
  }, [data])

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="p-6 relative">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <div className="space-y-4">
          <hgroup>
            <h3 className="text-xl font-medium">{title}</h3>
            {subtitle && <p className="text-base">{subtitle}</p>}
          </hgroup>

          {(locations.length > 10 || search) && (
            <SearchInput
              initialValue={search}
              onChange={onSearchChangedDebounced}
              placeholder="type to search for locations"
            />
          )}

          <ul className="space-y-3 h-96 overflow-y-auto scroll-shadows">
            {search === '' && (
              <ListItem
                title="All Locations"
                subtitle="Add location agnostic vendors across all of your shops, job sites, and offices."
                to="/vendors/new"
              />
            )}

            {locations.map((l) => (
              <ListItem
                key={l.id}
                title={`${l.name} ${l.defaultLocation ? '(Default)' : ''}`}
                subtitle={`${l.address.lineOne} ${l.address.city}, ${l.address.state}`}
                to={`/vendors/new?locationId=${l.id}`}
              />
            ))}
            {loading && (
              <div className="w-full flex flex-col gap-y-3">
                <Ghost className="block w-full h-20 bg-gray-200" />
                <Ghost className="block w-full h-20 bg-gray-200" />
                <Ghost className="block w-full h-20 bg-gray-200" />
              </div>
            )}
          </ul>
          {onSaveAndExit && (
            <div>
              <Action.S onClick={onSaveAndExit}>Save & Exit</Action.S>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectShippingLocationModal
