import { DeliveryMethod, PaymentMethod, StoreOrderStep } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import useSession from '@/buyers/hooks/useSession'
import A from '@/gf/components/A'
import ImageGallery from '@/gf/components/ImageGallery'
import Link from '@/gf/components/Link'
import {
  CheckCircleIcon,
  ExclamationIcon,
  ReceiptRefundIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { DownloadIcon } from '@heroicons/react/solid'
import { Section } from '../YourOrder'
import { useOrderContext } from '../context'
import Address from './Address'
import MachineInformation from './MachineInformation'
import PartsTable from './Parts/PartsTable'

const Parts = ({ canSelectParts }: { canSelectParts: boolean }) => {
  const { storeOrder, user } = useOrderContext()
  const { featureFlags } = useSession()

  const approvedLineItems = storeOrder.lineItems.filter((li) => !li.rejectedAt)
  const rejectedLineItems = storeOrder.lineItems.filter((li) => li.rejectedAt)

  return (
    <Box>
      {storeOrder.step !== StoreOrderStep.Canceled && (
        <Section>
          <div className="flex gap-4 items-center justify-between">
            {storeOrder.step === StoreOrderStep.Quoted ? (
              <A.S
                href={`/pdf/download/quote/${storeOrder.id}`}
                target="_blank"
                className="flex gap-2 items-center"
              >
                <DownloadIcon className="h-5 w-5" />
                <span>Download Quote</span>
              </A.S>
            ) : (
              <A.S
                href={`/pdf/download/po/${storeOrder.id}`}
                target="_blank"
                className="flex gap-2 items-center"
              >
                <DownloadIcon className="h-5 w-5" />
                <span>Download Purchase Order</span>
              </A.S>
            )}

            <div className="flex flex-row items-center gap-x-2">
              {storeOrder.deliveryMethod === DeliveryMethod.Pickup &&
                storeOrder.step === StoreOrderStep.Fulfilled && (
                  <Link.S to="report-problem" className="flex gap-2 items-center">
                    <ExclamationIcon className="h-5 w-5" />
                    <span>Report a Problem</span>
                  </Link.S>
                )}

              {featureFlags.buyersCreditMemos &&
                [
                  StoreOrderStep.PoSent,
                  StoreOrderStep.Fulfilling,
                  StoreOrderStep.Fulfilled,
                ].includes(storeOrder.step) &&
                storeOrder.paymentMethod === PaymentMethod.Direct && (
                  <Link.S to="log-credit-memo" className="flex gap-2 items-center">
                    <ReceiptRefundIcon className="h-5 w-5" />
                    <span>Log Credit Memo</span>
                  </Link.S>
                )}
            </div>
          </div>
        </Section>
      )}

      <PartsTable
        canSelectParts={canSelectParts}
        items={approvedLineItems}
        title={
          rejectedLineItems.length > 0 ? (
            <>
              <CheckCircleIcon className="text-green-500 h-5 w-5 inline-block" /> Approved Parts
            </>
          ) : (
            'Parts'
          )
        }
      />

      {rejectedLineItems.length > 0 && (
        <PartsTable
          canSelectParts={canSelectParts}
          items={rejectedLineItems}
          title={
            <>
              <XCircleIcon className="text-red-500 h-5 w-5 inline-block" /> Rejected Parts
            </>
          }
          className="mt-6"
        />
      )}

      <div className="divide-y divide-gray-200 mt-6">
        {rejectedLineItems.length > 0 && storeOrder.rejection && (
          <Section className="space-y-2">
            <h3 className="font-medium text-lg">Rejected Reason</h3>
            <p className="leading-6">
              {storeOrder.rejection.reason}
              {storeOrder.rejection.comments && (
                <>
                  <br />“{storeOrder.rejection.comments}”
                </>
              )}
            </p>
          </Section>
        )}

        {storeOrder.order.requestForQuote?.images &&
          storeOrder.order.requestForQuote.images.length > 0 && (
            <Section>
              <ImageGallery images={storeOrder.order.requestForQuote.images.map((i) => i.url)} />
            </Section>
          )}

        <Section className="space-y-2">
          <div className="flex gap-2 items-center">
            <span className="font-medium text-lg text-gray-900">
              {storeOrder.deliveryMethod === DeliveryMethod.Pickup
                ? 'Will Call Address'
                : 'Shipping Address'}
            </span>

            {storeOrder.deliveryMethod !== DeliveryMethod.Pickup &&
              storeOrder.shippingAddressEditable && (
                <Link.T to={`/orders/${storeOrder.id}/edit-shipping-address`}>Edit</Link.T>
              )}
          </div>

          {storeOrder.deliveryMethod === DeliveryMethod.Pickup && storeOrder.pickupAddress && (
            <Address address={storeOrder.pickupAddress} />
          )}

          {storeOrder.deliveryMethod !== DeliveryMethod.Pickup && storeOrder.shippingAddress && (
            <Address address={storeOrder.shippingAddress} />
          )}
        </Section>

        {user.claimed && (
          <MachineInformation machines={storeOrder.orgMachines} allowEdit disableLinks={false} />
        )}

        {storeOrder.order.requestForQuote?.partsRequest && (
          <Section className="space-y-2">
            <div className="font-medium text-lg text-gray-900">Comments</div>
            <p className="leading-6 prose whitespace-pre-wrap">
              {storeOrder.order.requestForQuote.partsRequest}
            </p>
          </Section>
        )}

        {storeOrder.timingDetails && storeOrder.timingDetails !== '' && (
          <Section className="space-y-2">
            <div className="font-medium text-lg text-gray-900">Expected lead time</div>
            <p className="leading-6 prose whitespace-pre-wrap">{storeOrder.timingDetails}</p>
          </Section>
        )}
      </div>
    </Box>
  )
}

export default Parts
