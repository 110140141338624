import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import AddAccountMachineModal from '@/buyers/components/AddAccountMachineModal'
import useToggle from '@/gf/hooks/useToggle'
import { CreateRequestPageDocument } from '@/buyers/_gen/gql'

const AddMachineButton = ({
  onMachineAdded,
  orgHasMachines,
}: {
  onMachineAdded(orgMachineId: string)
  orgHasMachines: boolean
}) => {
  const { organization } = useSession()
  const [open, toggle] = useToggle()

  return (
    <>
      <AddAccountMachineModal
        open={open}
        onClose={toggle.off}
        onAdd={(accountMachine) => {
          if (accountMachine) {
            onMachineAdded(accountMachine.id)
          }
        }}
        accountId={organization.id}
        refetchQueries={[CreateRequestPageDocument]}
      />

      {orgHasMachines ? (
        <Action.S className="flex-shrink-0" onClick={toggle.on}>
          Add Machine
        </Action.S>
      ) : (
        <Action.P className="flex-shrink-0 mt-2" onClick={toggle.on} color="blue">
          Add Machine
        </Action.P>
      )}
    </>
  )
}

export default AddMachineButton
