import { NewConversation } from '../../types'
import {
  Conversation as ConversationT,
  RequestForQuote as RequestForQuoteT,
  Store as StoreT,
  StoreOrder as StoreOrderT,
} from '@/buyers/_gen/gql'

import StoreOrderM from './StoreOrder'
import TrueFilter from './filters/True'

const ADMIN_CONVERSATION_NAME = 'Gearflow (Support)'
const HIDDEN_CUSTOMER_ORG_NAME = 'Customer'
const PAGE_SIZE = 30
const CONVERSATION_POLL_INTERVAL = 60000
const MESSAGES_POLL_INTERVAL = 5000

// Non-admin Conversation - newNonAdminConversation() defined in buyer & supplier Conversation modules
const nonAdminConversationCommon: Pick<
  NewConversation,
  'admin' | 'unreadMessages' | 'lastMessage' | 'newConversation'
> = {
  admin: false,
  unreadMessages: false,
  lastMessage: undefined,
  newConversation: 'true',
}
// Admin Conversations
const adminConversationCommon: Pick<
  NewConversation,
  'name' | 'admin' | 'unreadMessages' | 'lastMessage' | 'newConversation'
> = {
  name: ADMIN_CONVERSATION_NAME,
  admin: true,
  unreadMessages: false,
  lastMessage: undefined,
  newConversation: 'true',
}

// Filter out new conversations in the existing conversation list
const filterNewConversations = <
  T extends Pick<NewConversation, 'admin'> & {
    requestForQuote: { id: string } | undefined
    store: { id: string } | undefined
  }
>(
  newConversations: T[],
  conversations: (Pick<ConversationT, 'admin'> & {
    requestForQuote: Pick<RequestForQuoteT, 'id'> | null
    store: Pick<StoreT, 'id'> | null
  })[]
) =>
  newConversations.filter(
    (newConversation) =>
      !conversations.find(
        (conversation) =>
          conversation.requestForQuote?.id === newConversation.requestForQuote?.id &&
          conversation.admin === newConversation.admin &&
          conversation.store?.id === newConversation.store?.id
      )
  )

const isInternalOrgConversation = (
  conversation: Pick<ConversationT, 'admin'> & {
    store: object | null | undefined
  }
) => !conversation.admin && !conversation.store

// For admin conversations, return all StoreOrders; otherwise, filter StoreOrders by storeId
const filterRfqStoreOrders = <T extends Pick<StoreOrderT, 'id'> & { store: Pick<StoreT, 'id'> }>(
  storeOrders: T[],
  conversation:
    | (Pick<ConversationT, 'admin'> & { store: Pick<StoreT, 'id'> | null })
    | NewConversation
) =>
  conversation.admin || isInternalOrgConversation(conversation)
    ? storeOrders
    : storeOrders.filter((storeOrder) => storeOrder.store.id === conversation.store?.id)

const inboxTitle = (
  conversation:
    | {
        requestForQuote:
          | (Pick<RequestForQuoteT, 'shortId'> & {
              storeOrders: (Pick<StoreOrderT, 'shortId' | 'state'> & {
                store: Pick<StoreT, 'id'>
              })[]
            })
          | null
        storeOrder: (Pick<StoreOrderT, 'shortId'> & { store: Pick<StoreT, 'id'> }) | null
        store: Pick<StoreT, 'id'> | null
      }
    | NewConversation
) =>
  conversation.requestForQuote
    ? [
        `Request ${conversation.requestForQuote.shortId}`,
        ...conversation.requestForQuote.storeOrders
          .filter(
            (so) =>
              !StoreOrderM.isQuote({ state: so.state }) &&
              (!conversation.store || conversation.store.id === so.store.id)
          )
          .map((so) => `Order ${so.shortId}`),
      ]
    : `Order ${conversation.storeOrder?.shortId}`

const unreadMessagesConversationFilter = () =>
  TrueFilter.build({
    fieldId: 'unreadMessages',
  })

const getHiddenCustomerName = (userName: string | null) => userName?.split(' ')[0] || ''
const getHiddenCustomerOrgName = () => HIDDEN_CUSTOMER_ORG_NAME

const isNewConversation = (conversation: object | Pick<NewConversation, 'newConversation'>) =>
  'newConversation' in conversation

export default {
  ADMIN_CONVERSATION_NAME,
  PAGE_SIZE,
  CONVERSATION_POLL_INTERVAL,
  MESSAGES_POLL_INTERVAL,
  nonAdminConversationCommon,
  adminConversationCommon,
  filterNewConversations,
  filterRfqStoreOrders,
  inboxTitle,
  unreadMessagesConversationFilter,
  getHiddenCustomerName,
  getHiddenCustomerOrgName,
  isInternalOrgConversation,
  isNewConversation,
}
