import {
  DeliveryMethod,
  SelectedDealerLocationsQuery,
  useAddVendorContactMutation,
  useCreateVendorMutation,
  useSelectedDealerLocationsQuery,
  useSelectedLocationQuery,
  useVendorContactsLazyQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { ApolloErrorWithMessages, Maybe, Point } from '@/types'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'

const createValidationSchema = (locationIdRequired: boolean, fleetioIdRequired: boolean) =>
  Yup.object().shape({
    locationId: locationIdRequired
      ? Yup.string().required('Drop-off Location is required').nullable()
      : Yup.string().nullable(),
    dealerLocations: Yup.array().of(
      Yup.object().shape({
        dealerLocationId: Yup.string().required(),
        vendorId: Yup.string().nullable(),
        accountNumber: Yup.string().required('Account Number is required'),
        fleetioId: Yup.number()
          .nullable()
          .transform((v) => (Number.isNaN(v) ? null : v))
          .when('vendorId', (vendorId, schema) =>
            !vendorId && fleetioIdRequired
              ? schema.required('Fleetio Vendor ID is required')
              : schema.optional()
          ),
        contacts: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string().required(),
              contactName: Yup.string().required('Contact Name is required'),
              contactEmail: Yup.string().required('Contact Email is required'),
              contactPhone: Yup.string().nullable(),
              selected: Yup.boolean().nullable(),
            })
          )
          .compact((c) => c.selected === false)
          .min(1, 'Select a contact to send the request to')
          .required('Select a contact to send the request to'),
        deliveryMethod: Yup.string()
          .oneOf(
            [DeliveryMethod.Pickup, DeliveryMethod.Shipping, DeliveryMethod.VendorDelivery],
            'Select a delivery method'
          )
          .nullable()
          .required('Select a delivery method'),
      })
    ),
  })

type ContactFormValues = {
  id: string
  contactName: string
  contactEmail: string
  contactPhone: string
  selected: boolean
  isNew: boolean
}

type ContactFormValuesWithError = ContactFormValues & {
  error: Maybe<{ path: string; message: string }>
}

interface FormState {
  locationId: Maybe<string>
  dealerLocations: {
    dealerLocationId: string
    vendorId: Maybe<string>
    accountNumber: string
    contacts: ContactFormValues[]
    deliveryMethod: Maybe<DeliveryMethod>
    fleetioId: number | null
  }[]
}

export interface FormValues {
  locationId: string
  dealerLocations: {
    dealerLocationId: string
    vendorId: string
    contactIds: string[]
    deliveryMethod: DeliveryMethod
    accountNumber: string
    fleetioId: number | null
  }[]
}

type SelectedVendors = {
  vendorId: string
  contactIds: string[]
  deliveryMethod: DeliveryMethod
  accountNumber: string
}[]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormErrors = Record<any, any>

type PropertyError = {
  path: string
  message: string
}

const newContact = () =>
  ({
    id: `new-${new Date().getMilliseconds()}`,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    selected: true,
    isNew: true,
  } as ContactFormValues)

const createLocation = (
  dealerLocation: SelectedDealerLocationsQuery['dealerLocations']['entries'][number],
  selectedVendor: Maybe<SelectedVendors[number]>,
  disabledContactIds: string[]
) => {
  const vendorContacts = dealerLocation.vendor?.contacts ?? []

  const fullContactList =
    vendorContacts.length > 0
      ? vendorContacts
      : dealerLocation.contacts.length > 0
      ? dealerLocation.contacts
      : []

  const vendorPreferredContactIds = vendorContacts
    .filter(({ preferred, id }) => preferred && !disabledContactIds.includes(id))
    .map(({ id }) => id)

  const selectedContactIds = selectedVendor
    ? selectedVendor.contactIds
    : vendorPreferredContactIds.length > 0
    ? vendorPreferredContactIds
    : fullContactList.length === 1
    ? fullContactList.map((vc) => vc.id)
    : []

  return {
    dealerLocationId: dealerLocation.id,
    vendorId: dealerLocation.vendor?.id ?? null,
    accountNumber: selectedVendor?.accountNumber ?? dealerLocation.vendor?.accountNumbers[0] ?? '',
    contacts:
      fullContactList.length === 0
        ? [newContact()]
        : fullContactList.map(
            (c) =>
              ({
                id: c.id,
                contactName: c.name,
                contactEmail: c.email,
                contactPhone: c.phoneNumber ?? '',
                selected: selectedContactIds.includes(c.id),
                isNew: false,
              } as ContactFormValues)
          ),
    deliveryMethod: selectedVendor?.deliveryMethod ?? null,
    fleetioId: null,
  }
}

const createLocations = (
  data: SelectedDealerLocationsQuery,
  selectedVendors: SelectedVendors,
  disabledContactIds: string[]
) => {
  const vendorsById: Record<string, SelectedVendors[number]> = selectedVendors.reduce(
    (acc, vendor) => ({ ...acc, [vendor.vendorId]: vendor }),
    {}
  )

  return data.dealerLocations.entries.map((dl) =>
    createLocation(dl, dl.vendor?.id ? vendorsById[dl.vendor.id] ?? null : null, disabledContactIds)
  )
}

const createNearPoint = (location) => {
  if (location) {
    return { location, distance: null }
  }

  return null
}

const useContactAndDeliveryMethodForm = (
  dealerLocationsIds: string[],
  locationId: Maybe<string>,
  referencePoint: Point,
  vendors: SelectedVendors,
  locationIdRequired: boolean,
  orgMachineId: string | null,
  showFleetioId: boolean,
  disabledContactIds: string[],
  tutorial: boolean
) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [createVendorMutation] = useCreateVendorMutation({ client })
  const [createContactMutation] = useAddVendorContactMutation({ client })
  const [getVendorContacts] = useVendorContactsLazyQuery({ client })
  const [formState, setFormState] = useState<FormState>({
    locationId,
    dealerLocations: [],
  })

  const [errors, setErrors] = useState<FormErrors>({})

  const { data, loading } = useSelectedDealerLocationsQuery({
    client,
    variables: {
      orgId,
      nearPoint: createNearPoint(referencePoint),
      ids: dealerLocationsIds,
      tutorial,
    },
    onCompleted: (_data) => {
      setFormState({
        ...formState,
        dealerLocations: createLocations(_data, vendors, disabledContactIds),
      })
    },
  })

  const dealerLocations = data?.dealerLocations.entries ?? []

  const { data: locationData, loading: loadingLocation } = useSelectedLocationQuery({
    client,
    variables: { orgId, id: formState.locationId as string },
    skip: !formState.locationId,
  })

  const location = locationData?.shippingLocationsSearch.shippingLocations?.[0] ?? null

  const validate = useCallback(async () => {
    const validationSchema = createValidationSchema(locationIdRequired, !!showFleetioId)

    const validationResult = await validationSchema
      .validate(formState, { abortEarly: false, recursive: true })
      .catch((err: Yup.ValidationError) => err)

    const isValid = await validationSchema.isValid(formState, {
      abortEarly: false,
      recursive: true,
    })

    return {
      isValid,
      errors:
        validationResult.inner?.reduce(
          (acc, error) => set(acc, error.path as string, error.message),
          {}
        ) ?? {},
    }
  }, [formState])

  const clearError = useCallback((path) => unset(errors, path), [errors])

  const changeDealerLocation = (
    index: number,
    changes: Partial<NonNullable<FormState['dealerLocations']>[number]>
  ) => {
    setFormState((prev) => ({
      ...prev,
      dealerLocations: prev.dealerLocations.map((dl, i) =>
        i === index ? { ...dl, ...changes } : dl
      ),
    }))
  }

  const onLocationIdChange = (selectedLocationId: Maybe<string>) => {
    setFormState((prev) => ({ ...prev, locationId: selectedLocationId }))

    clearError('locationId')
  }

  const onContactChecked = (index: number, checked: boolean, contactIndex: number) => {
    const { contacts } = formState.dealerLocations[index]

    changeDealerLocation(index, {
      contacts: contacts.map((c, i) => (i === contactIndex ? { ...c, selected: checked } : c)),
    })
  }

  const onNewContactChanged = (
    index: number,
    contactId: string,
    changes: NonNullable<
      NonNullable<NonNullable<FormState['dealerLocations']>[number]>['contacts'][number]
    >
  ) => {
    const contactIndex = (formState.dealerLocations?.[index]?.contacts ?? []).findIndex(
      (c) => c?.id === contactId
    )
    const contacts = (formState.dealerLocations?.[index]?.contacts ?? []).map((c) =>
      c?.id === contactId ? { ...c, ...changes } : c
    )

    changeDealerLocation(index, {
      contacts,
    })

    clearError(`dealerLocations[${index}].contacts[${contactIndex}]`)
  }

  const onAccountNumberChanged = (index: number, accountNumber: string) => {
    changeDealerLocation(index, {
      accountNumber,
    })

    clearError(`dealerLocations[${index}].accountNumber`)
  }

  const onFleetioIdChanged = (index: number, fleetioId: number) => {
    changeDealerLocation(index, { fleetioId })
    clearError(`dealerLocations[${index}].fleetioId`)
  }

  const onDeliveryMethodChanged = (index: number, deliveryMethod: DeliveryMethod) => {
    changeDealerLocation(index, {
      deliveryMethod,
    })

    clearError(`dealerLocations[${index}].deliveryMethod`)
  }

  const onAddContact = (index: number) => {
    const existingContacts = formState.dealerLocations?.[index]?.contacts ?? []
    changeDealerLocation(index, {
      contacts: [...existingContacts, newContact()],
    })
  }

  const onRemoveContact = (index: number) => {
    const existingContacts = formState.dealerLocations?.[index]?.contacts ?? []
    const removedContact = existingContacts[existingContacts.length - 1]
    // Only remove new contacts
    if (removedContact && removedContact.isNew) {
      changeDealerLocation(index, {
        contacts: existingContacts.slice(0, -1),
      })
    }
  }

  const createVendor = async (vendor: {
    accountNumber: string
    name: string
    contacts: {
      name: string
      email: string
      phone: Maybe<string>
    }[]
    delivery: boolean
    dealerLocationId: string
    fleetioId: number | null
  }) => {
    try {
      const result = await createVendorMutation({
        variables: {
          accountNumbers: [vendor.accountNumber],
          contacts: vendor.contacts,
          delivery: vendor.delivery,
          dealerLocationId: vendor.dealerLocationId,
          brandIds: [],
          orgMachineId,
          name: vendor.name,
          offline: false,
          selectedBuyerId: null,
          fleetioId: vendor.fleetioId,
        },
      })

      return { vendorId: result.data?.createVendor as string, error: null }
    } catch (err) {
      const serverErrors = (err as ApolloErrorWithMessages)?.graphQLErrors
        .map((gqlErr) => gqlErr.fields.map((f) => f.value).flat())
        .flat()

      return { error: serverErrors?.[0] ?? 'Error while creating vendor', vendorId: null }
    }
  }

  const createContact = async (contact: {
    vendorId: string
    name: string
    email: string
    phoneNumber: string
  }) => {
    try {
      const result = await createContactMutation({
        variables: {
          vendorId: contact.vendorId,
          name: contact.name,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        },
      })
      return { contactId: result.data?.addVendorContact?.id as string, error: null }
    } catch (err) {
      const serverErrors = (err as ApolloErrorWithMessages)?.graphQLErrors
        .map((gqlErr) => gqlErr.fields.map((f) => f.value).flat())
        .flat()

      return { error: serverErrors?.[0] ?? 'Error while creating contact', contactId: null }
    }
  }

  const handleSubmit = async (submitFn: (values: FormValues) => void) => {
    const validation = await validate()

    if (validation.isValid) {
      const prePersistedDealerLocations = await Promise.all(
        formState.dealerLocations.map(async (dl, i) => {
          if (dl.vendorId) {
            const updatedContacts: ContactFormValuesWithError[] = await Promise.all(
              dl.contacts.map(async (c, contactIndex) => {
                if (c.isNew) {
                  const result = await createContact({
                    vendorId: dl.vendorId as string,
                    name: c.contactName,
                    email: c.contactEmail,
                    phoneNumber: c.contactPhone,
                  })

                  return {
                    ...c,
                    isNew: false,
                    id: result.contactId ? result.contactId : c.id,
                    error: result.error
                      ? {
                          path: `dealerLocations[${i}].contacts[${contactIndex}].contactEmail`,
                          message: result.error,
                        }
                      : null,
                  }
                }

                return { ...c, error: null }
              })
            )

            return {
              ...dl,
              vendorId: dl.vendorId,
              contacts: updatedContacts,
              error: null,
            }
          }

          const createVendorResult = await createVendor({
            accountNumber: dl.accountNumber,
            contacts: dl.contacts.map((c) => ({
              name: c.contactName,
              phone: c.contactPhone,
              email: c.contactEmail,
            })),
            delivery: dl.deliveryMethod === DeliveryMethod.VendorDelivery,
            dealerLocationId: dl.dealerLocationId,
            name: dealerLocations[i].name,
            fleetioId: dl.fleetioId,
          })

          const vendorId = createVendorResult.vendorId as string

          const contactsResult = await getVendorContacts({ variables: { id: vendorId } })

          return {
            ...dl,
            vendorId,
            contacts: (contactsResult.data?.vendor?.contacts ?? []).map(
              (c) =>
                ({
                  id: c.id,
                  contactEmail: c.email,
                  contactPhone: c.phoneNumber,
                  contactName: c.name,
                  isNew: false,
                  selected: true,
                  error: null,
                } as ContactFormValuesWithError)
            ),
            error: createVendorResult.error
              ? {
                  path: `dealerLocations[${i}].accountNumber`,
                  message: createVendorResult.error,
                }
              : null,
          }
        })
      )

      const asyncErrors = prePersistedDealerLocations
        .map((udl) => [
          ...((udl.error ? [udl.error] : []) as PropertyError[]),
          ...(udl.contacts.filter((uc) => !!uc.error).map((uc) => uc.error) as PropertyError[]),
        ])
        .flat()

      if (asyncErrors.length > 0) {
        const newErrors = {}
        asyncErrors.forEach((e) => {
          set(newErrors, e.path, e.message)
        })

        setErrors(newErrors)

        return
      }

      const newFormState = {
        locationId: formState.locationId,
        dealerLocations: prePersistedDealerLocations.map(({ error: _error, ...dl }) => ({
          dealerLocationId: dl.dealerLocationId,
          vendorId: dl.vendorId,
          accountNumber: dl.accountNumber,
          deliveryMethod: dl.deliveryMethod as DeliveryMethod,
          contacts: dl.contacts.map(({ error: _error2, ...c }) => c as ContactFormValues),
          fleetioId: dl.fleetioId,
        })),
      }

      setFormState(newFormState)

      const validForm = {
        locationId: formState.locationId as string,
        dealerLocations: prePersistedDealerLocations.map((dl) => ({
          dealerLocationId: dl.dealerLocationId,
          vendorId: dl.vendorId,
          deliveryMethod: dl.deliveryMethod as DeliveryMethod,
          contactIds: dl.contacts.filter((c) => c.selected).map((c) => c.id),
          accountNumber: dl.accountNumber,
          fleetioId: dl.fleetioId,
        })),
      }

      // await refetch()

      submitFn(validForm)
    } else {
      setErrors(validation.errors)
    }
  }

  return {
    dealerLocations,
    selectedLocation: location,
    loading: loading || loadingLocation || formState.dealerLocations.length === 0,
    errors,
    handleSubmit,
    values: formState,
    onLocationIdChange,
    changeDealerLocation,
    onAddContact,
    onRemoveContact,
    onNewContactChanged,
    onContactChecked,
    onAccountNumberChanged,
    onFleetioIdChanged,
    onDeliveryMethodChanged,
  }
}

export default useContactAndDeliveryMethodForm
