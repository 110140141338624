import {
  Outlet,
  Routes,
  Route,
  useParams,
  useNavigate,
  Navigate,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import nth from 'lodash/nth'
import { titleCase } from 'title-case'
import {
  PaperAirplaneIcon,
  PencilAltIcon,
  ClipboardIcon,
  XIcon,
  DocumentDuplicateIcon,
  ClipboardCopyIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'
import { twMerge } from 'tailwind-merge'

import { Tab } from '../../types'

import useMsgs from '@/gf/hooks/useMsgs'
import useSession from '../hooks/useSession'
import useToggle from '@/gf/hooks/useToggle'
import usePage from '@/gf/hooks/usePage'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useApolloWsClient from '@/buyers/hooks/useApolloWsClient'
import {
  useFetchConversationsQuery,
  useRfqQuotedSubscription,
  RequestForQuoteStep,
  useRequestQuery,
  RequestQuery,
  InventoryFulfillmentMatch,
  RfqPriority,
} from '@/buyers/_gen/gql'

import EqualsFilter from '@/gf/modules/filters/Equals'
import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import RequestForQuoteM from '@/gf/modules/RequestForQuote'
import ConversationM from '@/gf/modules/Conversation'

import MultiButton, { MultiButtonAction } from '@/gf/components/MultiButton'
import Page from '@/gf/components/Page'
import RfqStepBadge from '@/buyers/components/RfqStepBadge'
import Tabs from '@/gf/components/Tabs'
import Spinner from '@/gf/components/Spinner'
import Frame from '../components/Frame'
import CancelRfqModal from './RFQ/CancelRfqModal'
import NotFound from './NotFound'
import Request from './RFQ/Request'
import Details from './RFQ/Details'
import Messages from './RFQ/Messages'
import TimeOpenPill from '@/gf/components/TimeOpenPill'
import NewRFQMessageModal from './RFQ/NewRFQMessageModal'
import BrokerWarning from '../components/BrokerWarning'
import AssignUserDropdown from '../components/AssignUserDropdown'
import UserInitials from '@/gf/components/UserInitials'
import {
  SimpleTooltip,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/gf/components/next/Tooltip'
import { Info } from './Order/Header'
import NeededBy from '@/gf/components/NeededBy'
import { RequestTutorialBanner } from '../components/TutoriaBanners'

type ContextType = {
  rfq: RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]
  user: RequestQuery['user']
  org: RequestQuery['org']
  refetchRfq: ReturnType<typeof useRequestQuery>['refetch']
}

const Priority = ({ priority }: { priority: RfqPriority }) => (
  <div className="inline-flex flex-row gap-x-1 items-center justify-center">
    <div
      className={twMerge(
        'px-2 py-1 inline-flex flex-row rounded text-sm font-medium border',
        priority === RfqPriority.High
          ? 'bg-red-100 border-red-200 text-red-700'
          : priority === RfqPriority.Medium
          ? 'bg-yellow-100 border-yellow-200 text-yellow-600'
          : 'bg-green-100 border-green-200 text-green-700'
      )}
    >
      {priority === RfqPriority.High ? 'High' : priority === RfqPriority.Medium ? 'Medium' : 'Low'}
    </div>
  </div>
)

export const useContext = () => useOutletContext<ContextType>()

const RFQ = () => {
  const gqlClient = useGqlClient()
  const params = useParams()
  const {
    user: { id: userId },
    organization,
  } = useSession()
  const msgr = useMsgs()[1]
  const { rfqId } = params as { rfqId: string }
  const navigate = useNavigate()
  const location = useLocation()

  const queryResult = useRequestQuery({
    variables: {
      orgId: organization.id,
      userId,
      filters: [['equals', 'id', rfqId]],
      page: 1,
    },
    client: gqlClient,
    fetchPolicy: 'no-cache',
  })

  const { user, org } = queryResult.data || {}

  const rfq =
    queryResult.data && (nth(queryResult.data?.requestForQuotesSearch.requestForQuotes, 0) || null)

  const [cancelRequestOpen, cancelRequestToggler] = useToggle(false)
  const [page, _] = usePage()

  const conversationFilters = [EqualsFilter.build({ fieldId: 'requestForQuoteId', text: rfqId })]

  const conversationsResult = useFetchConversationsQuery({
    client: gqlClient,
    variables: {
      filters: FormatFilters.andFilters(FilterSet.toApiFilters(conversationFilters)),
      page,
      pageSize: ConversationM.PAGE_SIZE,
    },
    pollInterval: ConversationM.CONVERSATION_POLL_INTERVAL,
  })

  useRfqQuotedSubscription({
    variables: { orgId: organization.id },
    client: useApolloWsClient(),
    onData: ({ data }) => {
      if (data.data?.rfqQuoted === rfqId)
        queryResult.refetch().then(() => {
          msgr.add('Quote received.')
        })
    },
  })

  const tabs: Tab[] = [
    { name: 'Request' },
    { name: 'Details' },
    {
      name: 'Messages',
      showNotification: conversationsResult.data?.fetchConversations.conversations.some(
        (c) => c.unreadMessages
      ),
    },
  ]

  const title = `Request ${RequestForQuoteM.shortenId(rfqId)}`

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Requests', href: '/rfqs' },
      { name: title, href: `/rfqs/${rfqId}` },
    ],
  }

  if (rfq === null) return <NotFound />

  const actions: MultiButtonAction[] = []

  if (rfq) {
    const matchesInternalInventoryItems = rfq.inventoryMatch !== InventoryFulfillmentMatch.None

    if (rfq.step === RequestForQuoteStep.Inbound && user?.can.approveRequests)
      actions.push({
        key: 'approve',
        display: 'Approve',
        icon: PaperAirplaneIcon,
        iconClassName: 'rotate-90',
        description: 'Review and send the request to your vendor(s).',
        onClick: () => {
          if (matchesInternalInventoryItems) {
            navigate(`/rfqs/${rfq.id}/approve/part-details`)
          } else if (rfq.resumeDealer && rfq.requestForQuoteVendors.length !== 0) {
            navigate(`/rfqs/${rfq.id}/approve/review`)
          } else if (rfq.resumeDealer) {
            navigate(`/rfqs/${rfq.id}/approve/dealers`)
          } else {
            navigate(`/rfqs/${rfq.id}/approve/part-details`)
          }
        },
      })

    if (rfq.step === RequestForQuoteStep.Inbound && user?.can.approveRequests)
      actions.push({
        key: 'edit',
        display: 'Edit',
        icon: PencilAltIcon,
        description: 'Edit the request before sending it.',
        onClick: () => navigate(`/rfqs/${rfq.id}/approve/details`),
      })

    if (
      [
        RequestForQuoteStep.Inbound,
        RequestForQuoteStep.Submitted,
        RequestForQuoteStep.Quoted,
      ].includes(rfq.step)
    )
      actions.push({
        key: 'cancel',
        display: 'Cancel',
        icon: XIcon,
        description: 'Cancel the request.',
        onClick: cancelRequestToggler.on,
      })

    if (
      [
        RequestForQuoteStep.Inbound,
        RequestForQuoteStep.Submitted,
        RequestForQuoteStep.Quoted,
      ].includes(rfq.step) &&
      user?.can.approveRequests &&
      !rfq.createdByTutorial
    ) {
      const inventoryAction = {
        key: 'in-inventory',
        display: 'In Inventory',
        icon: ClipboardIcon,
        description: 'Fulfill this request from parts you have in stock.',
        onClick: () => navigate(`/rfqs/${rfq.id}/fulfill-from-inventory`),
      }

      if (matchesInternalInventoryItems) {
        actions.unshift(inventoryAction)
      } else {
        actions.push(inventoryAction)
      }
    }

    if (rfq.step === RequestForQuoteStep.Inbound && !rfq.createdByTutorial)
      actions.push({
        key: 'order-manually',
        display: 'Order Manually',
        icon: ClipboardCopyIcon,
        description: 'Order items outside of Gearflow.',
        onClick: () => navigate(`/rfqs/${rfq.id}/order-manually`),
      })

    if (!rfq.createdByTutorial)
      actions.push({
        key: 'copy-request',
        display: 'Copy Request',
        icon: DocumentDuplicateIcon,
        description: 'Start a new request by copying over the information from this request.',
        onClick: () => navigate(`/rfqs/${rfq.id}/copy`),
      })
  }

  const titleRightContent = (() => {
    if (!rfq) return <></>
    return (
      <>
        <div className="flex flex-row items-start gap-x-8">
          <Info label="Priority">
            <div className="-mb-0.5">
              <Priority priority={rfq.priority} />
            </div>
          </Info>
          {rfq.neededBy && (
            <Info label="Needed By">
              <div className="inline-flex items-center gap-x-2">
                {rfq.machineDown && (
                  <SimpleTooltip text="Machine down">
                    <span className="p-1 flex items-center justify-center border border-red-200 bg-red-100 rounded-full">
                      <ExclamationIcon className="h-4 w-4 flex shrink-0 text-red-700" />
                    </span>
                  </SimpleTooltip>
                )}
                <NeededBy date={rfq.neededBy} />
              </div>
            </Info>
          )}
          <Info label="Time Open">
            <TimeOpenPill createdAt={rfq.insertedAt} tooltipLeft />
          </Info>
          <Info label="Owner">
            <div className={rfq.assignedUser ? '-my-0.5' : undefined}>
              <AssignUserDropdown
                rfq={rfq}
                onUserAssigned={queryResult.refetch}
                placement="bottom-end"
                menuClassName="mt-1"
                trigger={
                  <span>
                    {rfq.assignedUser ? (
                      <Tooltip placement="left">
                        <TooltipTrigger>
                          <UserInitials
                            name={rfq.assignedUser.name ?? rfq.assignedUser.email}
                            size="sm"
                          />
                        </TooltipTrigger>
                        <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                          <div className="flex flex-col gap-y-2">
                            <div className="flex flex-col">
                              {rfq.assignedUser.name && <span>{rfq.assignedUser.name}</span>}
                              <span>({rfq.assignedUser.email})</span>
                            </div>
                            <span className="text-gray-500 italic">
                              Click to assign someone else
                            </span>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <span className="text-blue-500 underline">Assign</span>
                    )}
                  </span>
                }
              />
            </div>
          </Info>

          <div className="flex lg:hidden">
            <MultiButton align="left" actions={actions} />
          </div>
        </div>
      </>
    )
  })()

  return (
    <Frame
      breadcrumbs={breadcrumbs}
      contentClassName="flex"
      topBanner={rfq?.createdByTutorial ? <RequestTutorialBanner /> : undefined}
    >
      <Page
        title={
          <div className="inline-flex flex-col gap-y-1.5">
            {title}
            {rfq?.workOrderNumber && (
              <span className="text-sm text-gray-900 whitespace-nowrap">
                Work Order: {rfq?.workOrderNumber}
              </span>
            )}
          </div>
        }
        titleMetadata={
          rfq && (
            <div className="flex grow flex-row items-center gap-x-4">
              <RfqStepBadge step={rfq.step} />
              {rfq.brokerRequestForQuoteId && (
                <BrokerWarning.Request
                  className="-my-2"
                  fulfillingRfqId={rfq.brokerRequestForQuoteId}
                />
              )}
            </div>
          )
        }
        titleRightContent={titleRightContent}
      >
        <CancelRfqModal
          open={cancelRequestOpen}
          onClose={({ didCancelRfq }) => {
            if (didCancelRfq) {
              navigate('/rfqs')
            } else {
              cancelRequestToggler.off()
            }
          }}
          requestForQuoteId={rfqId}
        />

        <Tabs
          className="mt-4"
          currentTabName={titleCase(params['*']?.split('/')[0] as string)}
          onTabSelect={(name) => navigate(name.toLowerCase())}
          tabs={tabs}
        />

        <NewRFQMessageModal rfqId={rfqId} />

        {rfq && user && org ? (
          <Routes>
            <Route
              path="*"
              element={
                <Outlet
                  context={
                    { rfq, user, org, refetchRfq: queryResult.refetch } satisfies ContextType
                  }
                />
              }
            >
              <Route
                path=""
                element={
                  <Navigate to={`request${location.search ? `${location.search}` : ''}`} replace />
                }
              />

              <Route
                path="request/*"
                element={<Request refetch={queryResult.refetch} actions={actions} />}
              />
              <Route path="details" element={<Details refetch={queryResult.refetch} />} />
              {['messages', 'messages/:conversationId', 'messages/store/:storeId'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Messages
                      paginatedConversations={conversationsResult.data?.fetchConversations}
                      refetch={conversationsResult.refetch}
                      error={conversationsResult.error}
                    />
                  }
                />
              ))}
              <Route
                path="messages/internalOrg"
                element={
                  <Messages
                    paginatedConversations={conversationsResult.data?.fetchConversations}
                    refetch={conversationsResult.refetch}
                    error={conversationsResult.error}
                    internalOrg
                  />
                }
              />
            </Route>
          </Routes>
        ) : (
          <Spinner className="mt-4" />
        )}
      </Page>
    </Frame>
  )
}

export default RFQ
