import AutocompleteMultiInputNext from '@/buyers/components/AutocompleteMultiInputNext'
import Action from '@/gf/components/Action'
import Button from '@/gf/components/ButtonOld'
import Field from '@/gf/components/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import TextField from '@/gf/components/TextField'
import TextInput from '@/gf/components/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { PlusIcon, XIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import {
  SearchBrandsDocument,
  SearchBrandsQuery,
  SearchBrandsQueryVariables,
  useSearchBrandsQuery,
  useUpdateVendorMutation,
} from '../../_gen/gql'
import useGqlClient from '../../hooks/useGqlClient'
import { useContext } from '../Vendor'

interface VendorForm {
  accountNumbers: string[]
  name: string
  brandIds: string[]
  offline: boolean
  delivery: boolean
  fleetioId: number | null
}

const VendorForm = ({
  onAfterSave,
  onCancel,
}: {
  onAfterSave: () => void
  onCancel: () => void
}) => {
  const [_, messages] = useMsgs()
  const client = useGqlClient()
  const [updateVendor] = useUpdateVendorMutation({ client })
  const { vendor, org } = useContext()
  const showFleetioId = org?.orgApps.some((oa) => oa.appId === 'fleetio')

  const vendorSchema = useMemo(
    () =>
      yup.object({
        accountNumbers: yup.array().of(yup.string().required().label('Account Number')),
        name: yup.string().required().label('Vendor Name'),
        brandIds: yup.array(yup.string()),
        offline: yup.boolean(),
        delivery: yup.boolean(),
        ...(showFleetioId
          ? {
              fleetioId: yup
                .number()
                .nullable()
                .required('Fleetio Vendor ID is required')
                .transform((v) => (Number.isNaN(v) ? null : v)),
            }
          : {}),
      }),
    [showFleetioId]
  )

  const form = useForm<VendorForm>({
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      accountNumbers: vendor.accountNumbers || [''],
      name: vendor.name || '',
      brandIds: vendor.brands.map((b) => b.id),
      offline: vendor.offline,
      fleetioId: vendor.fleetioId,
    },
    resolver: yupResolver(vendorSchema),
  })

  const brandIds = useWatch({
    name: 'brandIds',
    control: form.control,
    defaultValue: vendor.brands.map((b) => b.id),
  })

  const { data: selectedBrandsData, previousData: selectedBrandsPreviousData } =
    useSearchBrandsQuery({
      client,
      variables: { ids: brandIds, searchTerm: null },
      skip: brandIds.length === 0,
    })

  const selectedBrands =
    brandIds.length > 0
      ? selectedBrandsData?.brands ?? selectedBrandsPreviousData?.brands ?? []
      : []

  const onSubmit = async (values: VendorForm) => {
    try {
      await updateVendor({
        variables: {
          vendorId: vendor.id,
          name: values.name || '',
          accountNumbers: values.accountNumbers,
          brandIds: values.brandIds,
          offline: !!values.offline,
          fleetioId: values.fleetioId || null,
        },
      })

      onAfterSave()
    } catch (err) {
      console.error(err)
      messages.add('Oops! Looks like something went wrong.', 'negative')
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="p-4 flex flex-col gap-y-4 w-86">
      <Controller
        control={form.control}
        name="accountNumbers"
        render={({ field }) => (
          <Field label="Account Number">
            {field.value.map((value, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={i}>
                <div className="flex gap-x-2 pb-1">
                  <TextInput
                    value={value}
                    ref={i === 0 ? field.ref : undefined}
                    onChange={(e) => {
                      const updated = [...field.value]
                      updated[i] = e.target.value
                      field.onChange(updated)
                    }}
                  />
                  {i > 0 && (
                    <button
                      type="button"
                      onClick={() => field.onChange(field.value.filter((_value, i2) => i2 !== i))}
                    >
                      <XIcon className="text-gray-700 h-5 w-5" />
                    </button>
                  )}
                </div>
                <FieldError error={form.formState.errors.accountNumbers?.[i]?.message} />
              </Fragment>
            ))}

            <Action.T
              onClick={() => field.onChange([...field.value, ''])}
              className="text-sm no-underline flex items-center gap-x-1.5 pt-1"
            >
              <PlusIcon className="inline-block h-4 w-4" /> Add additional account number
            </Action.T>
          </Field>
        )}
      />

      <TextField
        label="Vendor Name"
        errorText={form.formState.errors.name?.message}
        {...form.register('name')}
      />

      <Controller
        control={form.control}
        name="brandIds"
        render={({ field }) => (
          <Field label="Brands">
            <AutocompleteMultiInputNext<
              SearchBrandsQuery,
              SearchBrandsQueryVariables,
              SearchBrandsQuery['brands'][number]
            >
              gqlClient={client}
              query={SearchBrandsDocument}
              queryVars={(search) => ({ searchTerm: search, ids: null })}
              onChange={(brands) => field.onChange(brands.map((b) => b.id))}
              transformQueryToData={(queryData) => queryData.brands}
              transformDatumToOption={(brand) => ({
                value: brand.id,
                label: brand.name,
              })}
              selectedValues={selectedBrands}
              // Filter out the locations the user is already subscribed to
              filterOption={(option) => !field.value.includes(option.value)}
              reactSelectOptions={{
                isClearable: false,
                styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
                menuPortalTarget: document.body,
              }}
            />
          </Field>
        )}
      />

      {showFleetioId && (
        <TextField
          label="Fleetio Vendor ID"
          errorText={form.formState.errors.fleetioId?.message}
          type="number"
          {...form.register('fleetioId')}
        />
      )}

      <div className="flex gap-x-4 mt-4">
        <Button
          type="button"
          className="font-semibold justify-center tracking-wide border-slate-500 text-slate-500 bg-transparent hover:bg-slate-50 w-1/3"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button type="submit" className="font-semibold justify-center tracking-wide w-2/3">
          Save
        </Button>
      </div>
    </form>
  )
}

export default VendorForm
