import { useVendorSelectQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import BigSelect from '@/gf/components/BigSelect'

const VendorSelect = ({
  vendorId,
  setVendorId,
}: {
  vendorId: string | null
  setVendorId: (id: string | null) => void
}) => {
  const { data, loading, refetch } = useVendorSelectQuery({
    client: useGqlClient(),
    notifyOnNetworkStatusChange: true,
  })

  const options = data?.org?.paginatedVendors.vendors
    .filter((v) => !v.internal)
    .map((v) => ({ value: v.id, label: v.name }))

  return (
    <BigSelect
      options={options}
      filter={(search) => refetch({ search })}
      selectedValue={vendorId}
      setSelectedValue={setVendorId}
      loading={loading}
    />
  )
}

export default VendorSelect
