import { LineSvgProps, ResponsiveLine } from '@nivo/line'
import { debounce, get, pick, values } from 'lodash'
import { DateTime } from 'luxon'
import { isNaN } from 'mathjs'
import { useState } from 'react'

import {
  SortByInput,
  SortOrder,
  useSetOrganizationAnnualPartsBudgetMutation,
} from '@/buyers/_gen/gql'

import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { SecondDegreeStandardAggregateT } from '@/buyers/modules/Reporting'
import RequestForQuote from '@/buyers/modules/RequestForQuote'
import RequestsTableHeader from '@/buyers/modules/RequestsTableHeader'
import useChartPointSymbol, { pointToDate } from '@/gf/hooks/useChartPointSymbol'
import Money from '@/gf/modules/Money'
import Chart from './Chart'
import useReportingFormQueryParams from './useReportingFormQueryParams'
import useSelectedValues, { SelectedValue } from './useSelectedValues'
import useSortBy from './useSortBy'

import Ghost from '@/gf/components/Ghost'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import DurationInput, {
  DATE_FORMAT,
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import NivoLineTooltip from '@/gf/components/Reports/NivoPointTooltip'
import ReportingTable, {
  Column,
  NoResults,
  getBreakdownSelectMessage,
} from '@/gf/components/Reports/ReportingTable'
import Tabs from '@/gf/components/next/Tabs'
import TitleMetric from '@/gf/components/next/TitleMetric'
import NoDecimalMoneyInput from '@/gf/components/next/forms/NoDecimalMoneyInput'
import { Transition } from '@headlessui/react'
import Tag from '../../../gf/components/Reports/Tag'
import { getOrgMachineName } from './OrgMachine'
import { OutlierBadgeValues, getReportingTableValue } from './ReportingTableValue'
import RequestsTable from './RequestsTable'
import Scorecards from './Scorecards'
import StandardColumns from './StandardColumns'
import useMetrics from './useMetrics'

type Tab = 'Machine' | 'Vendor' | 'Urgency' | 'Category'
type Metric = 'total' | 'savings'

const formatMoneyValue = (value: number) =>
  Money.format(Money.fromInt(value, 'USD'), { maximumFractionDigits: 0 })

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getColumnTotalCost = <
  T extends { total: { sum: number } | null },
  U extends { total: { sum: OutlierBadgeValues | null } }
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: 'Cost',
  sortByField: 'total.sum',
  getValue: (row) =>
    getReportingTableValue<T, U>(row, (r) => r.total?.sum ?? null, formatMoneyValue, {
      aggregate,
      getValues: (a) => a?.total.sum ?? null,
      downIsGood: true,
    }),
})

const getColumnRequestsCount = <
  T extends { total: { count: number } | null },
  U extends { total: { count: OutlierBadgeValues | null } }
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'total.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.total?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getColumnCostSavings = <
  T extends { costSavings: { sum: number } | null },
  U extends { costSavings: { sum: OutlierBadgeValues | null } }
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: 'Savings',
  sortByField: 'costSavings.sum',
  getValue: (row) =>
    getReportingTableValue<T, U>(row, (r) => r.costSavings?.sum ?? null, formatMoneyValue, {
      aggregate,
      getValues: (a) => a?.costSavings.sum ?? null,
    }),
})

const getColumnCostSavingRequestsCount = <
  T extends { costSavings: { count: number } | null },
  U extends { costSavings: { count: OutlierBadgeValues | null } }
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'costSavings.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.costSavings?.count ?? null,
      (value) => value,
      undefined
    ),
})

const getMetricRequestKey = (metric: Metric) => (metric === 'savings' ? 'costSavings' : 'total')
const getMetricAggregatePath = (metric: Metric) =>
  metric === 'savings' ? 'costSavings.sum' : 'total.sum'

const useReportingMetrics = <TabT extends Tab, MetricT extends Metric>({
  durationStart,
  durationEnd,
  metric,
  tab,
  urgentRequestsOnly,
  selectedValue,
  selectedDate,
  sortBy,
  setSortBy,
  requestsSortBy,
  toggleSelectedValue,
  clearSelectedValue,
}: {
  durationStart: DateTime
  durationEnd: DateTime
  metric: MetricT
  tab: TabT
  urgentRequestsOnly: boolean
  selectedValue: SelectedValue | undefined
  selectedDate: DateTime | undefined
  sortBy: SortByInput
  setSortBy: (prev: SortByInput) => void
  requestsSortBy: SortByInput
  toggleSelectedValue: ReturnType<typeof useSelectedValues>['toggleSelectedValue']
  clearSelectedValue: ReturnType<typeof useSelectedValues>['clearSelectedValue']
}) => {
  const metricFilter = (row) => get(row, getMetricRequestKey(metric)) !== null

  const {
    error,
    prevError,
    refetch,
    // Aggregates
    aggregatedRequestMetrics,
    prevAggregatedRequestMetrics,
    aggregatedStoreMetrics,
    aggregatedMachineMetrics,
    aggregatedUrgencyMetrics,
    aggregatedCategoryMetrics,
    // Chart data
    chartData,
    chartDailyAverage,
    selectedChartData,
    // Table metrics
    orderedStoreMetrics,
    orderedMachineMetrics,
    orderedUrgencyMetrics,
    orderedCategoryMetrics,
    filteredOrderedRequestMetrics,
  } = useMetrics({
    form: { durationStart, durationEnd, tab, urgentRequestsOnly },
    selectedValues: selectedValue ? [selectedValue] : [],
    selectedDate,
    sortBy,
    requestsSortBy,
    storeFilter: (row) => !!row.store && metricFilter(row),
    creatorFilter: (row) => !!row.creator && metricFilter(row),
    purchaserFilter: (row) => !!row.assignedUser && metricFilter(row),
    machineFilter: (row) => !!row.orgMachine && metricFilter(row),
    categoryFilter: (row) => !!row.category.id && metricFilter(row),
    urgencyFilter: () => true,
    requestFilter: metricFilter,
    getChartValue: (row) => get(row, getMetricAggregatePath(metric)),
  })

  const getMetricColumns = (
    aggregate:
      | {
          total: SecondDegreeStandardAggregateT
          costSavings: SecondDegreeStandardAggregateT
        }
      | undefined
  ) =>
    metric === 'savings'
      ? {
          metric: getColumnCostSavings({ aggregate }),
          requests: getColumnCostSavingRequestsCount(),
        }
      : {
          metric: getColumnTotalCost({ aggregate }),
          requests: getColumnRequestsCount(),
        }

  const breakdown =
    tab === 'Vendor' ? (
      <ReportingTable
        data={orderedStoreMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.store?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.store?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.store && toggleSelectedValue({ value: row.store.id, display: row.store.name }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Vendor',
            getValue: (row) => row.store?.name,
          },
          ...values(pick(getMetricColumns(aggregatedStoreMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : tab === 'Urgency' ? (
      <ReportingTable
        data={orderedUrgencyMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.urgency ?? ''}
        checkbox={{
          getChecked: (row) => row.urgency === selectedValue?.value,
          onToggleRow: (row) =>
            toggleSelectedValue({
              value: row.urgency,
              display: RequestForQuote.urgencyToDisplay(row.urgency),
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Urgency',
            getValue: (row) => RequestForQuote.urgencyToDisplay(row.urgency),
          },
          ...values(pick(getMetricColumns(aggregatedUrgencyMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : tab === 'Category' ? (
      <ReportingTable
        data={orderedCategoryMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.category.id ?? ''}
        checkbox={{
          getChecked: (row) => row.category.id === selectedValue?.value,
          onToggleRow: (row) =>
            toggleSelectedValue({
              value: row.category.id,
              display: row.category.name,
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Category',
            getValue: (row) => row.category.name,
          },
          ...values(pick(getMetricColumns(aggregatedCategoryMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : (
      <ReportingTable
        data={orderedMachineMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.orgMachine?.id}
        checkbox={{
          getChecked: (row) => row.orgMachine?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.orgMachine &&
            toggleSelectedValue({
              value: row.orgMachine.id,
              display: getOrgMachineName(row.orgMachine),
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Machine',
            getValue: (row) => getOrgMachineName(row.orgMachine),
          },
          ...values(pick(getMetricColumns(aggregatedMachineMetrics), ['requests', 'metric'])),
        ]}
      />
    )

  if (error || prevError) return { error: error || prevError, refetch }
  return {
    refetch,
    current: {
      aggregate: aggregatedRequestMetrics,
      chart: {
        data: chartData,
        selectedCharts: selectedChartData,
        dailyAverage: chartDailyAverage,
      },
      breakdown,
      orderedStoreMetrics,
      orderedMachineMetrics,
      orderedUrgencyMetrics,
      orderedCategoryMetrics,
      filteredOrderedRequestMetrics,
    },
    prev: { aggregate: prevAggregatedRequestMetrics },
  }
}

const sumChartData = (chartData: { x: string; y: number | null }[]) =>
  chartData.reduce(
    (acc, current) => {
      const { sum, data } = acc
      if (
        sum === null ||
        DateTime.fromFormat(current.x, DATE_FORMAT).startOf('day') > DateTime.now().startOf('day')
      ) {
        // Return null for days after today
        return { sum: null, data: [...data, { ...current, y: null }] }
      }
      const newSum = sum + (current.y ?? 0)
      return { sum: newSum, data: [...data, { ...current, y: newSum }] }
    },
    { sum: 0, data: [] } as {
      sum: number | null
      data: { x: string; y: number | null }[]
    }
  ).data

const Cost = () => {
  const { organization } = useSession()
  // TODO: create a hook to put most of this stuff in that's the same across these reports
  const [selectedDate, setSelectedDate] = useState<DateTime>()
  const [selectedChartTab, setSelectedChartTab] = useState('Activity')
  const { form, updateForm } = useReportingFormQueryParams<Tab, Metric>({
    defaultDurationId,
    defaultDurationDates,
    defaultTab: 'Machine',
    defaultMetric: 'total',
  })
  const { selectedValue, toggleSelectedValue, clearSelectedValue } = useSelectedValues(form.tab)
  const { sortBy, setSortBy } = useSortBy(form.tab, {
    field: getMetricAggregatePath(form.metric),
    order: SortOrder.Desc,
  })
  const [requestsSortBy, setRequestsSortBy] = useState<SortByInput>({
    field: getMetricRequestKey(form.metric),
    order: SortOrder.Desc,
  })
  const gqlClient = useGqlClient()
  const [setOrganizationAnnualPartsBudget] = useSetOrganizationAnnualPartsBudgetMutation({
    client: gqlClient,
  })
  const debouncedSetOrganizationBudget = debounce(setOrganizationAnnualPartsBudget, 1000)
  const [yearlyPartsBudget, setYearlyPartsBudget] = useState<number | undefined>(
    organization.annualPartsBudget ? Money.toDecimal(organization.annualPartsBudget) : undefined
  )
  // Do we need to account for leap year?
  const dailyPartsBudget = yearlyPartsBudget ? yearlyPartsBudget / 365 : undefined
  const onChangeYearlyPartsBudget = (newYearlyPartsBudget: number | undefined) => {
    setYearlyPartsBudget(newYearlyPartsBudget)
    debouncedSetOrganizationBudget({
      variables: {
        annualPartsBudget: newYearlyPartsBudget
          ? Money.fromDecimal(newYearlyPartsBudget, 'USD')
          : null,
      },
    })
  }

  const clearFilters = () => {
    updateForm({ urgentRequestsOnly: false })
    setSelectedDate(undefined)
    clearSelectedValue()
  }

  const days = form.durationEnd.diff(form.durationStart).as('days')
  const pointSymbol = useChartPointSymbol(selectedDate)

  const costGraph: Partial<LineSvgProps> = {
    ...Chart.getBaseGraph({ days, pointSymbol }),
    yFormat: (cents) => (typeof cents === 'number' ? formatMoneyValue(cents) : ''),
  }

  const { error, current, prev } = useReportingMetrics({
    ...pick(form, ['durationStart', 'durationEnd', 'metric', 'tab', 'urgentRequestsOnly']),
    selectedValue,
    selectedDate,
    sortBy,
    setSortBy,
    requestsSortBy,
    toggleSelectedValue,
    clearSelectedValue,
  })

  return (
    <ChartBreakdownLayout
      error={!!error}
      titleMetric={
        <TitleMetric
          title={
            form.metric === 'total' ? 'Parts Spend' : <StandardColumns.CostSavingsTooltipTitle />
          }
          value={
            typeof current?.aggregate === 'undefined'
              ? undefined
              : (form.metric === 'total'
                  ? current.aggregate.total?.sum
                  : current.aggregate.costSavings?.sum) ?? null
          }
          comparisonValue={
            typeof prev?.aggregate === 'undefined'
              ? undefined
              : (form.metric === 'total'
                  ? prev?.aggregate?.total?.sum
                  : prev?.aggregate?.costSavings?.sum) ?? null
          }
          downIsGood={form.metric === 'total'}
          valueToDisplay={formatMoneyValue}
          duration={form}
        />
      }
      durationInput={
        <DurationInput
          start={form.durationStart}
          end={form.durationEnd}
          durationId={form.durationId}
          onChange={({ start, end, durationId }) =>
            updateForm({ durationStart: start, durationEnd: end, durationId })
          }
        />
      }
      scorecards={
        <Scorecards
          className="max-w-screen-sm flex grow"
          scorecards={[
            {
              name: 'Parts Spend',
              value:
                typeof current?.aggregate === 'undefined'
                  ? undefined
                  : current.aggregate.total?.sum ?? null,
              fromValue:
                typeof prev?.aggregate === 'undefined'
                  ? undefined
                  : prev?.aggregate?.total?.sum ?? null,
              formatValue: formatMoneyValue,
              downIsGood: true,
              active: form.metric === 'total',
              onClick: () => updateForm({ metric: 'total' }),
            },
            {
              name: StandardColumns.costSavingsTitle,
              tooltip: StandardColumns.costSavingsTooltip,
              value:
                typeof current?.aggregate === 'undefined'
                  ? undefined
                  : current?.aggregate?.costSavings?.sum ?? null,
              fromValue:
                typeof prev?.aggregate === 'undefined'
                  ? undefined
                  : prev?.aggregate?.costSavings?.sum ?? null,
              formatValue: formatMoneyValue,
              active: form.metric === 'savings',
              onClick: () => updateForm({ metric: 'savings' }),
            },
          ]}
        />
      }
      chart={
        <div className="w-full h-full flex flex-col gap-y-6">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="w-full flex flex-row justify-start items-center gap-x-6">
              <Tabs
                tabs={[
                  { name: 'Activity' },
                  // Hide the Trend tab unless on the "Total" metric
                  ...(form.metric === 'total' ? [{ name: 'Trend' }] : []),
                ]}
                selectedTabName={selectedChartTab}
                onTabSelect={(tab) => setSelectedChartTab(tab)}
              />
              {selectedChartTab === 'Trend' && (
                // Add negative margin y, so the tab height doesn't change when this field appears
                <div className="-my-0.5 relative">
                  <Transition
                    className="z-10 px-1 absolute left-1.5 -top-2.5 rounded-md bg-white"
                    show={typeof yearlyPartsBudget !== 'undefined'}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <span className="text-xs text-gray-900">Yearly budget</span>
                  </Transition>
                  <NoDecimalMoneyInput
                    className="w-40 flex shrink-0"
                    value={yearlyPartsBudget}
                    setValue={onChangeYearlyPartsBudget}
                    placeholder="Yearly budget"
                  />
                </div>
              )}
            </div>
            <div className="w-full flex flex-row justify-end items-center gap-x-2">
              {selectedDate && (
                <Tag onRemove={() => setSelectedDate(undefined)}>
                  {selectedDate.toLocaleString(DateTime.DATE_MED)}
                </Tag>
              )}
              {selectedValue && (
                <Tag onRemove={() => clearSelectedValue()}>{selectedValue.display}</Tag>
              )}
            </div>
          </div>
          {!current?.chart.data ? (
            <Ghost className="w-full h-full flex bg-gray-300" />
          ) : (
            // Need a high z-index for the chart for the tooltips to show over the surrounding UI
            <div className="w-full h-full z-[1000]">
              <ResponsiveLine
                {...costGraph}
                data={[
                  {
                    id: 'All Requests',
                    data:
                      selectedChartTab === 'Activity'
                        ? current.chart.data
                        : sumChartData(current.chart.data),
                    color: Chart.getDataColor(0),
                  },
                  ...(selectedChartTab === 'Activity'
                    ? typeof current.chart.dailyAverage !== 'undefined'
                      ? [
                          {
                            id: 'Daily average',
                            data: current.chart.data.map(({ x }) => ({
                              x,
                              y: current.chart.dailyAverage,
                              noPoint: true,
                            })),
                            color: Chart.SECONDARY_LINE_COLOR,
                          },
                        ]
                      : []
                    : dailyPartsBudget && !isNaN(dailyPartsBudget)
                    ? [
                        {
                          id: 'Parts budget',
                          data: current.chart.data.map(({ x }, index) => ({
                            x,
                            // Convert to cents
                            y: dailyPartsBudget * (index + 1) * 100,
                            noPoint: true,
                          })),
                          color: Chart.SECONDARY_LINE_COLOR,
                        },
                      ]
                    : []),
                  ...(current.chart.selectedCharts?.map((selected, index) => ({
                    ...selected,
                    data:
                      selectedChartTab === 'Activity' ? selected.data : sumChartData(selected.data),
                    color: Chart.getDataColor(index + 1),
                  })) || []),
                ].reverse()}
                tooltip={NivoLineTooltip}
                onClick={(point) => {
                  const pointDate = pointToDate(point.data.x)
                  if (selectedDate && pointDate?.equals(selectedDate)) setSelectedDate(undefined)
                  else setSelectedDate(pointDate)
                }}
              />
            </div>
          )}
        </div>
      }
      breakdownTable={
        <div className="flex grow flex-col gap-y-3 bg-white border border-gray-300 rounded-xl shadow-sm overflow-hidden">
          <div className="px-4 flex flex-col gap-y-3">
            <div className="pt-6 flex flex-row items-center gap-x-6">
              <Tabs
                tabs={[
                  { name: 'Machine' },
                  { name: 'Urgency' },
                  { name: 'Vendor' },
                  { name: 'Category' },
                ]}
                selectedTabName={form.tab}
                onTabSelect={(tab) => updateForm({ tab: tab as Tab })}
              />
            </div>
            <span className="text-sm text-gray-900">
              {form.tab === 'Machine'
                ? getBreakdownSelectMessage(current?.orderedMachineMetrics?.length, 'machine')
                : form.tab === 'Vendor'
                ? getBreakdownSelectMessage(current?.orderedStoreMetrics?.length, 'vendor')
                : form.tab === 'Category'
                ? getBreakdownSelectMessage(current?.orderedCategoryMetrics?.length, 'category')
                : getBreakdownSelectMessage(current?.orderedUrgencyMetrics?.length, 'urgency')}
            </span>
          </div>
          <div className="px-4 pb-0.5 overflow-x-scroll overflow-y-scroll">
            {current?.breakdown ? current.breakdown : null}
          </div>
        </div>
      }
      historyTable={
        <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
          <div className="px-4 text-lg text-gray-900 font-medium">
            <RequestsTableHeader
              selectedValue={selectedValue}
              selectedDate={selectedDate}
              form={form}
            />
          </div>
          <div className="px-4 pb-3 max-h-96 overflow-x-scroll overflow-y-scroll">
            <ReportingTable
              data={current?.filteredOrderedRequestMetrics}
              sortBy={{ sortBy: requestsSortBy, setSortBy: setRequestsSortBy }}
              getRowKey={({ requestForQuote }) => requestForQuote.id}
              noResults={<NoResults onClearFilters={clearFilters} />}
              columns={[
                RequestsTable.getRequestColumn(),
                RequestsTable.getCreatedColumn(),
                form.metric === 'savings'
                  ? {
                      header: 'Savings',
                      getValue: (row) =>
                        getReportingTableValue(
                          row,
                          (r) => r.costSavings ?? null,
                          formatMoneyValue,
                          undefined
                        ),
                      sortByField: 'costSavings',
                    }
                  : {
                      header: 'Cost',
                      // TODO: need a better way to do this (for example, a clearer Number cell or Cost cell abstraction)
                      getValue: (row) =>
                        getReportingTableValue(row, (r) => r.total ?? null, formatMoneyValue, {
                          aggregate: current?.aggregate,
                          getValues: (a) => a?.total ?? null,
                          downIsGood: true,
                        }),
                      sortByField: 'total',
                    },
                RequestsTable.getCreatorColumn(),
                RequestsTable.getVendorColumn(),
              ]}
            />
          </div>
        </div>
      }
    />
  )
}

export default Cost
