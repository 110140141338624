import {
  DealerLocationDetailsQuery,
  PreferredSetting,
  useCreateVendorMutation,
  useDealerLocationDetailsLazyQuery,
  useSetVendorPreferredSettingMutation,
  useUpdateVendorMutation,
  useVendorModalQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import TextInput from '@/gf/components/next/forms/TextInput'
import Select from '@/gf/components/next/Select'
import RedAlert from '@/gf/components/RedAlert'
import useToggle from '@/gf/hooks/useToggle'
import { Maybe } from '@/types'
import { ApolloError } from '@apollo/client'
import { PlusIcon, XIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import DealerLocationModalHeader from './AddVendorModal/DealerLocationModalHeader'
import NewVendorAlert from './AddVendorModal/NewVendorAlert'
import BrandsSelector from './BrandsSelector'

type DealerLocation = DealerLocationDetailsQuery['dealerLocations']['entries'][number]

type FormValues = {
  name: string
  accountNumbers: { value: string }[]
  contactName: string
  contactEmail: string
  contactPhone: string
  delivery: boolean
  fleetioId: number | null
  preferenceSettings: {
    value: PreferredSetting
    brandIds: string[]
  }
}

const NewContactButton = ({ onClick }) => (
  <Action.T onClick={onClick} className="text-sm text-left text-blue-600 no-underline block w-full">
    <PlusIcon className="inline-block h-4 -mt-0.5" /> Add New Contact
  </Action.T>
)

const AddVendorModal = ({
  onClose,
  dealerLocationId,
  orgMachineId,
  onVendorCreated,
  onVendorUpdated,
  showCancelButton = false,
  transparentFooter = false,
  onCancel,
}: {
  onClose: () => void
  dealerLocationId: Maybe<string>
  orgMachineId?: string | null
  onVendorCreated: (id: string | null | undefined, name: string) => void
  onVendorUpdated: (name: string) => void
  showCancelButton?: boolean
  transparentFooter?: boolean
  onCancel?: () => void
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [createVendorMutation] = useCreateVendorMutation({ client })
  const [updateVendor] = useUpdateVendorMutation({ client })
  const [setVendorPreferredSetting] = useSetVendorPreferredSettingMutation({ client })
  const [fetchDealerLocation] = useDealerLocationDetailsLazyQuery({ client })
  const [dealerLocation, setDealerLocation] = useState<DealerLocation | null>(null)
  const [showContactFields, showContactFieldsToggle] = useToggle()
  const [saving, setSaving] = useState(false)
  const [createVendorErrors, setCreateVendorErrors] = useState<string[]>([])
  const { org } = useVendorModalQuery({ variables: { orgId }, client }).data || {}
  const showFleetioId = org?.orgApps.some((oa) => oa.appId === 'fleetio')

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Account Name is required'),
        accountNumbers: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string().required('Account Number is required'),
            })
          )
          .min(1, 'Account Number is required')
          .ensure(),
        contactName: Yup.string().required('Contact Name is required'),
        contactEmail: Yup.string()
          .trim()
          .required('Email is required')
          .email('Invalid email format'),
        contactPhone: Yup.string(),
        delivery: Yup.boolean(),
        preferenceSettings: Yup.object().shape({
          value: Yup.string().oneOf([
            PreferredSetting.None,
            PreferredSetting.All,
            PreferredSetting.Brands,
          ]),
          brandIds: Yup.array(Yup.string()),
        }),
        ...(showFleetioId
          ? {
              fleetioId: Yup.number()
                .nullable()
                .required('Fleetio Vendor ID is required')
                .transform((v) => (Number.isNaN(v) ? null : v)),
            }
          : {}),
      }),
    [showFleetioId]
  )

  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      accountNumbers: [{ value: '' }],
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      delivery: false,
      fleetioId: null,
      preferenceSettings: {
        value: PreferredSetting.Brands,
        brandIds: [],
      },
    },
    shouldFocusError: true,
    resolver: yupResolver(validationSchema),
  })
  const accountNumbers = useFieldArray({ control: form.control, name: 'accountNumbers' })

  const values = useWatch({ control: form.control })
  const contacts = dealerLocation?.contacts ?? []

  const initForm = async () => {
    showContactFieldsToggle.on()

    if (dealerLocationId) {
      const { data } = await fetchDealerLocation({ variables: { orgId, dealerLocationId } })
      const dl = data?.dealerLocations.entries[0] ?? null
      setDealerLocation(dl)
      if ((dl?.contacts ?? []).length > 0) showContactFieldsToggle.off()

      if (dl?.vendor) {
        const contact = dl.vendor.contacts[0]

        form.setValue('name', dl.vendor.name)
        form.setValue(
          'accountNumbers',
          dl.vendor.accountNumbers.map((value) => ({
            value,
          })) || [{ value: '' }]
        )
        form.setValue('contactName', contact.name || '')
        form.setValue('contactEmail', contact.email)
        form.setValue('contactPhone', contact.phoneNumber || '')

        form.setValue('preferenceSettings', {
          value: dl.vendor.preferredFor,
          brandIds: dl.vendor.brands.map((b) => b.id),
        })
      } else {
        form.setValue('name', dl?.name || '')

        form.setValue('preferenceSettings', {
          value: PreferredSetting.Brands,
          brandIds: dl?.brands.map((b) => b.id) ?? [],
        })
      }
    } else {
      setDealerLocation(null)
    }
  }

  useEffect(() => {
    form.reset()
    initForm()
  }, [dealerLocationId])

  const contactOptions = useMemo(
    () => contacts.map((c) => ({ value: c.email, label: c.name })),
    [contacts]
  )

  const selectedContact = useMemo(
    () => contactOptions.find((c) => c.value === values.contactEmail) ?? null,
    [contactOptions, values.contactEmail]
  )

  const onContactSelected = (contactEmail) => {
    const contact = contacts.find((c) => c.email === contactEmail)

    if (contact) {
      form.setValue('contactName', contact.name)
      form.setValue('contactEmail', contact.email)
      form.setValue('contactPhone', contact.phoneNumber ?? '')
    }
  }

  const onSubmit = async (formValues: FormValues) => {
    setSaving(true)
    setCreateVendorErrors([])

    if (dealerLocation?.vendor) {
      await updateVendor({
        variables: {
          vendorId: dealerLocation.vendor.id,
          name: formValues.name,
          accountNumbers: formValues.accountNumbers.map(({ value }) => value),
          brandIds: formValues.preferenceSettings.brandIds,
          offline: dealerLocation.vendor.offline,
          fleetioId: dealerLocation.vendor.fleetioId,
        },
      })

      await setVendorPreferredSetting({
        variables: {
          vendorId: dealerLocation.vendor.id,
          preferredFor: formValues.preferenceSettings.value,
        },
      })

      onVendorUpdated(formValues.name)
      setSaving(false)
      onClose()
    } else {
      createVendorMutation({
        variables: {
          name: formValues.name,
          accountNumbers: formValues.accountNumbers.map(({ value }) => value),
          contacts: [
            {
              name: formValues.contactName,
              email: formValues.contactEmail,
              phone: formValues.contactPhone,
            },
          ],
          delivery: formValues.delivery,
          offline: false,
          selectedBuyerId: null,
          brandIds: formValues.preferenceSettings.brandIds,
          orgMachineId: orgMachineId || null,
          fleetioId: formValues.fleetioId,
          dealerLocationId,
        },
      })
        .then(({ data }) => {
          onVendorCreated(data?.createVendor, formValues.name)
          onClose()
        })
        .catch(
          (
            err: Omit<ApolloError, 'graphQLErrors'> & {
              graphQLErrors: (ApolloError['graphQLErrors'][number] & {
                fields: { value: string }[]
              })[]
            }
          ) => {
            const errors = err.graphQLErrors.flatMap((e) => e.fields.map((f) => f.value))
            setCreateVendorErrors(errors)
          }
        )
        .finally(() => {
          setSaving(false)
        })
    }
  }

  return (
    <>
      <div className="flex flex-shrink-0 justify-end px-3 pt-3">
        <CloseModalButton onClick={onClose} />
      </div>

      <div className="overflow-y-auto flex-grow px-6 pb-6 text-gray-900 flex flex-col gap-y-6">
        {dealerLocation && <DealerLocationModalHeader dealerLocation={dealerLocation} />}

        {!dealerLocationId || (dealerLocation && dealerLocation.numOfQuotes === 0) ? (
          <NewVendorAlert />
        ) : null}

        <h3 className="text-2xl font-medium">Add Your Vendor&apos;s Information</h3>

        {createVendorErrors.length > 0 && (
          <RedAlert>
            {createVendorErrors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </RedAlert>
        )}

        <form
          className="flex flex-col gap-y-6"
          id="vendor-form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {!dealerLocation && (
            <Field label="Vendor Name" error={form.formState.errors.name?.message}>
              <TextInput {...form.register('name')} autoFocus />
            </Field>
          )}

          <Controller
            control={form.control}
            name="preferenceSettings.brandIds"
            render={({ field, fieldState }) => (
              <Field
                label="Brands (optional)"
                help="What brands do you order from this vendor?"
                error={fieldState.error?.message}
              >
                <BrandsSelector value={field.value} onChange={field.onChange} />
              </Field>
            )}
          />

          <Field label="Account Number">
            {accountNumbers.fields?.map((n, i) => (
              <>
                <div className="flex gap-x-2 pt-1">
                  <TextInput key={n.id} {...form.register(`accountNumbers.${i}.value`)} />
                  {i > 0 && (
                    <button type="button" onClick={() => accountNumbers.remove(i)}>
                      <XIcon className="text-gray-700 h-5 w-5" />
                    </button>
                  )}
                </div>

                <FieldError error={form.formState.errors.accountNumbers?.[i]?.value?.message} />
              </>
            ))}
            <Action.T
              onClick={() => accountNumbers.append({ value: '' })}
              className="text-sm no-underline flex items-center gap-x-1.5 pt-1"
            >
              <PlusIcon className="inline-block h-4 w-4" /> Add additional account number
            </Action.T>
          </Field>

          {showContactFields ? (
            <div className="flex flex-col gap-y-2">
              <Field
                label="Your Account Representative"
                error={form.formState.errors.contactName?.message}
              >
                <TextInput
                  {...form.register('contactName')}
                  placeholder="Enter name"
                  disabled={!!dealerLocation?.vendor}
                />
              </Field>

              <Controller
                control={form.control}
                name="contactPhone"
                render={({ field, fieldState }) => (
                  <Field error={fieldState.error?.message}>
                    <PhoneInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Phone number"
                      disabled={!!dealerLocation?.vendor}
                    />
                  </Field>
                )}
              />

              <Field error={form.formState.errors.contactEmail?.message}>
                <TextInput
                  {...form.register('contactEmail')}
                  placeholder="Email address"
                  disabled={!!dealerLocation?.vendor}
                />
              </Field>
            </div>
          ) : (
            <Field
              label="Account Representative"
              error={
                form.formState.errors.contactName?.message ??
                form.formState.errors.contactEmail?.message ??
                form.formState.errors.contactPhone?.message
              }
            >
              <Select
                options={contactOptions}
                value={selectedContact}
                onChange={(o) => o && onContactSelected(o.value)}
                placeholder="Enter name"
                // eslint-disable-next-line react/no-unstable-nested-components
                noOptionsMessage={() => <NewContactButton onClick={showContactFieldsToggle.on} />}
              />
            </Field>
          )}

          {showFleetioId && (
            <Field label="Fleetio Vendor ID" error={form.formState.errors.fleetioId?.message}>
              <TextInput type="number" {...form.register('fleetioId')} />
            </Field>
          )}
        </form>
      </div>
      <div
        className={classNames(
          'py-3 px-6 flex',
          showCancelButton ? 'justify-between' : 'justify-end',
          !transparentFooter && 'bg-gray-50'
        )}
      >
        {showCancelButton && <Action.S onClick={onCancel || onClose}>Back</Action.S>}

        <Action.P
          color="blue"
          className="font-medium"
          onClick={form.handleSubmit(onSubmit)}
          disabled={!form.formState.isDirty}
          performing={saving}
        >
          Save
        </Action.P>
      </div>
    </>
  )
}

export default AddVendorModal
