import { useSelectedMachineQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import DateInput from '@/gf/components/inputs/Date'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import TextArea from '@/gf/components/next/forms/TextArea'
import TextInput from '@/gf/components/next/forms/TextInput'
import { Maybe } from '@/types'
import { PlusIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import { useEffect, useRef } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import FormSection from './FormSection'
import InventoryFulfillmentMessage from './InventoryFulfillmentMessage'
import MachineSelect from './MachineSelect'
import AddMachineButton from './PartDetailsStep/AddMachineButton'
import BranchSelector from './PartDetailsStep/BranchSelector'
import DuplicatedRfqsWarning from './PartDetailsStep/DuplicatedRfqsWarning'
import PartImages from './PartDetailsStep/PartImages'
import PrioritySelector from './PartDetailsStep/PrioritySelector'
import UploadPartsFileButton from './PartDetailsStep/UploadPartsFileButton'
import StartOverButton from './StartOverButton'
import StickyBottomBar from './StickyBottomBar'
import { PartialRequestUpdate, Priority, Urgency } from './types'

const partRequestSchema = Yup.object().shape({
  partNumber: Yup.string()
    .trim()
    .when('description', (description, schema: Yup.StringSchema) =>
      !description ? schema.required('Part number or Description are required') : schema.optional()
    ),
  description: Yup.string().trim().max(255, 'Must be less than 256 characters'),
  quantity: Yup.number().required('Quantity is required'),
  pictures: Yup.array().of(Yup.string()),
})

const createValidationSchema = (
  billingCompanyRequired: boolean,
  workOrderNumberRequired: boolean
) =>
  Yup.object().shape({
    workOrderNumber: workOrderNumberRequired
      ? Yup.string().required('Work order number is required')
      : Yup.string().optional(),
    machineOrgId: Yup.string().when(
      'machineInvolved',
      (machineInvolved, schema: Yup.StringSchema) =>
        machineInvolved
          ? schema.nullable().required('Machine is required')
          : schema.nullable().transform(() => null)
    ),
    machineInvolved: Yup.boolean(),
    billingCompanyId: billingCompanyRequired
      ? Yup.string().nullable().required('Billing Company is required')
      : Yup.string().nullable().optional(),
    urgency: Yup.object().shape({
      priority: Yup.number()
        .oneOf([Priority.HIGH, Priority.MEDIUM, Priority.LOW])
        .required('Select the priority'),
      machineDown: Yup.boolean().required(),
      neededByDate: Yup.string().required(),
    }),
    parts: Yup.array().of(partRequestSchema).min(1),
    comments: Yup.string(),
  })

export type Part = {
  partNumber: string
  description: string
  quantity: number
  externalId: string | null
  taskNumber: string | null
  pictures: string[]
}

type FormValues = {
  billingCompanyId: Maybe<string>
  workOrderNumber: string
  machineOrgId: Maybe<string>
  machineInvolved: boolean
  urgency: { priority: Priority; machineDown: boolean; neededByDate: DateTime }
  parts: Part[]
  comments: string
}

const emptyCallback = () => undefined

const PartDetailsStep = ({
  reset,
  request,
  updateRequest,
  onSubmit,
  billingCompanyRequired,
  workOrderNumberRequired,
  commentsEnabled = true,
  uploadPartsEnabled = true,
  rfqId,
  submitButtonText,
  filfillFromInventoryMessageEnabled = false,
  onOrgMachineSelected = emptyCallback,
  onAddPartsClicked = emptyCallback,
  onPriorityChanged = emptyCallback,
  orgHasMachines = true,
}: {
  reset?: () => void
  onSubmit: () => void
  billingCompanyRequired: boolean
  workOrderNumberRequired: boolean
  commentsEnabled?: boolean
  partsPicturesEnabled?: boolean
  uploadPartsEnabled?: boolean
  urgencyEnabled?: boolean
  rfqId?: string
  submitButtonText: string
  filfillFromInventoryMessageEnabled?: boolean
  onOrgMachineSelected?: (orgMachineId: string) => void
  onAddPartsClicked?: () => void
  onPriorityChanged?: (priority: Priority) => void
  orgHasMachines?: boolean
} & PartialRequestUpdate) => {
  const client = useGqlClient()
  const priorityRef = useRef<HTMLFieldSetElement>(null)
  const form = useForm<FormValues>({
    shouldFocusError: true,
    defaultValues: {
      billingCompanyId: request.billingCompanyId,
      workOrderNumber: request.workOrderNumber ?? '',
      machineOrgId: request.machineOrgId ?? undefined,
      machineInvolved: request.machineInvolved,
      urgency: {
        priority: request.urgency?.priority,
        machineDown: request.urgency?.machineDown ?? false,
        neededByDate: request.urgency?.neededByDate,
      },
      parts:
        request.parts?.map((p) => ({
          partNumber: p.partNumber ?? '',
          description: p.description ?? '',
          pictures: p.pictures ?? [],
          quantity: p.quantity ?? 1,
          externalId: p.externalId,
          taskNumber: p.taskNumber,
        })) ?? [],
      comments: request.comments ?? '',
    },
    resolver: yupResolver(createValidationSchema(billingCompanyRequired, workOrderNumberRequired)),
  })

  const partsField = useFieldArray({ name: 'parts', control: form.control })
  const formValues = useWatch({ control: form.control })

  useEffect(() => {
    updateRequest({
      billingCompanyId: formValues.billingCompanyId ?? null,
      workOrderNumber: formValues.workOrderNumber,
      machineInvolved: formValues.machineInvolved,
      machineOrgId: formValues.machineOrgId,
      parts: (formValues.parts ?? []).map((p) => ({
        partNumber: p.partNumber ?? null,
        description: p.description ?? null,
        pictures: p.pictures ?? [],
        externalId: p.externalId ?? null,
        taskNumber: p.taskNumber ?? null,
        quantity: p.quantity ? Number(p.quantity) : 1,
      })),
      comments: formValues.comments,
      urgency: formValues.urgency as Urgency,
    })
  }, [formValues])

  const { data: machineData, loading: loadingMachine } = useSelectedMachineQuery({
    client,
    variables: { id: formValues.machineOrgId as string },
    skip: !formValues.machineOrgId,
  })

  const machine = machineData?.orgMachine

  const machineSelected = !!formValues.machineOrgId || !formValues.machineInvolved

  return (
    <>
      <form
        onSubmit={form.handleSubmit(onSubmit, (errors) => {
          // Only scroll to urgency if there are no other errors (since it's at the end of the form)
          if (errors.urgency && Object.keys(errors).length === 1) {
            priorityRef.current?.scrollIntoView()
          }
        })}
        className="space-y-4"
      >
        <FormSection>
          {workOrderNumberRequired && (
            <Field label="Work order number" error={form.formState.errors.workOrderNumber?.message}>
              <TextInput {...form.register('workOrderNumber')} />
            </Field>
          )}

          {billingCompanyRequired && (
            <Controller
              control={form.control}
              name="billingCompanyId"
              render={({ field }) => (
                <Field
                  label="What billing company?"
                  error={form.formState.errors.billingCompanyId?.message ?? null}
                  className="mt-4"
                >
                  <BranchSelector value={field.value} onChange={field.onChange} />
                </Field>
              )}
            />
          )}

          <Field
            label="What machine do you need the part for?"
            className={workOrderNumberRequired ? 'mt-6' : undefined}
            error={form.formState.errors.machineOrgId?.message}
          >
            {!formValues.machineOrgId ? (
              <>
                {formValues.machineInvolved && (
                  <Controller
                    name="machineOrgId"
                    control={form.control}
                    render={({ field }) => (
                      <div className="flex gap-x-3">
                        {orgHasMachines && (
                          <MachineSelect
                            value={field.value}
                            onChange={(value) => {
                              if (value != null) {
                                onOrgMachineSelected(value)
                              }

                              field.onChange(value)
                            }}
                            id="machine"
                          />
                        )}

                        <AddMachineButton
                          onMachineAdded={field.onChange}
                          orgHasMachines={orgHasMachines}
                        />
                      </div>
                    )}
                  />
                )}

                <Controller
                  name="machineInvolved"
                  control={form.control}
                  render={({ field }) => (
                    <label className="flex items-center gap-x-2 py-2 text-sm">
                      <Checkbox
                        checked={!field.value}
                        onChange={(e) => field.onChange(!e.target.checked)}
                      />
                      No machine involved
                    </label>
                  )}
                />
              </>
            ) : loadingMachine ? (
              <p>loading...</p>
            ) : (
              <div className="flex items-start text-sm gap-x-2">
                <div className="flex-grow">
                  <span className="block font-medium leading-5">{machine?.name}</span>
                  <p className="text-gray-600 flex flex-col leading-5">
                    <span>Serial Number: {machine?.serialNumber}</span>
                    <span>Make: {machine?.machine.make}</span>
                    <span>Model: {machine?.machine.model}</span>
                    <span>Year: {machine?.machine.year}</span>
                    {machine?.description && <span>{machine?.description}</span>}
                  </p>
                </div>
                <Action.T
                  onClick={() => {
                    form.setValue('machineOrgId', null)
                  }}
                  className="font-medium flex-shrink-0"
                >
                  Change
                </Action.T>
              </div>
            )}
          </Field>
        </FormSection>

        <FormSection disabled={!machineSelected}>
          {filfillFromInventoryMessageEnabled &&
          formValues.parts &&
          rfqId &&
          formValues.parts?.filter((p) => !!p.partNumber).length > 0 ? (
            <InventoryFulfillmentMessage rfqId={rfqId} parts={formValues.parts as Part[]} />
          ) : null}

          <DuplicatedRfqsWarning
            partNumbers={(formValues?.parts ?? []).map((p) => p.partNumber ?? '')}
            machineOrgId={formValues.machineOrgId ?? null}
            ignoreRfqIds={rfqId ? [rfqId] : []}
          />

          {partsField.fields.map((field, index) => (
            <div className="border-b border-gray-300 mb-6 pb-6 space-y-4" key={field.id}>
              <div className="flex items-start">
                {index === 0 && (
                  <div className="flex-grow">
                    <h5 className="leading-6">What part do you need?</h5>

                    <p className="text-sm text-gray-600 mt-2">
                      If you do not have the part number, provide a detailed description so that
                      vendors can assist in identifying it.
                    </p>
                  </div>
                )}

                {index === 0 ? (
                  uploadPartsEnabled && (
                    <UploadPartsFileButton
                      disabled={!machineSelected}
                      onFileUploaded={(parts) => partsField.replace(parts)}
                    />
                  )
                ) : (
                  <Action.T
                    className="flex-shrink-0 text-sm"
                    onClick={() => partsField.remove(index)}
                  >
                    Remove
                  </Action.T>
                )}
              </div>

              <div className="flex gap-x-3">
                <Field
                  label="Part Number"
                  className="flex-grow"
                  error={form.formState.errors.parts?.[index]?.partNumber?.message}
                  htmlFor={`partNumber${index}`}
                >
                  <TextInput
                    {...form.register(`parts.${index}.partNumber`)}
                    disabled={!machineSelected}
                    id={`partNumber${index}`}
                  />
                </Field>

                <Field
                  label="Quantity"
                  className="w-16 flex-shrink-0"
                  error={form.formState.errors.parts?.[index]?.quantity?.message}
                >
                  <TextInput
                    type="number"
                    min="0"
                    {...form.register(`parts.${index}.quantity`)}
                    disabled={!machineSelected}
                  />
                </Field>
              </div>

              <Field
                label="Part Description"
                error={form.formState.errors.parts?.[index]?.description?.message}
              >
                <TextInput
                  {...form.register(`parts.${index}.description`)}
                  disabled={!machineSelected}
                />
              </Field>

              <Controller
                name={`parts.${index}.pictures`}
                control={form.control}
                render={({ field: pictureField }) => (
                  <PartImages
                    value={formValues.parts?.[index]?.pictures ?? []}
                    desc={
                      index === 0 && (
                        <p className="text-gray-600 mt-1">
                          Pictures can reduce processing time and help verify fitment with your
                          machine.
                        </p>
                      )
                    }
                    onChange={pictureField.onChange}
                    addDisabled={!machineSelected}
                  />
                )}
              />
            </div>
          ))}

          <Action.S
            className="font-medium text-sm flex items-center"
            onClick={() => {
              partsField.append({
                partNumber: '',
                description: '',
                quantity: 1,
                externalId: null,
                taskNumber: null,
                pictures: [],
              })

              onAddPartsClicked()
            }}
            disabled={!machineSelected}
          >
            <PlusIcon className="mr-2 h-4 inline-block" /> Add Another Part
          </Action.S>
        </FormSection>

        <FormSection disabled={!machineSelected}>
          <h5 className="leading-6">What is your urgency on getting your parts?</h5>
          <p className="text-sm text-gray-600 mt-2">
            This helps vendors prioritize your request and provide accurate quotes.
          </p>

          <Controller
            name="urgency"
            control={form.control}
            render={({ field, formState }) => (
              <div className="text-sm mt-4">
                <fieldset ref={priorityRef}>
                  <PrioritySelector
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)

                      if (value.priority) {
                        onPriorityChanged(value.priority)
                      }
                    }}
                    disabled={!machineSelected}
                  />
                </fieldset>

                <FieldError
                  error={
                    formState.errors.urgency?.priority?.message ??
                    formState.errors.urgency?.neededByDate?.message
                  }
                />

                {field.value.priority && (
                  <Field label="Select Date" className="mt-4">
                    <DateInput
                      value={field.value.neededByDate}
                      onChange={(value) => field.onChange({ ...field.value, neededByDate: value })}
                      className="max-w-44"
                      disabled={!machineSelected}
                    />
                  </Field>
                )}
              </div>
            )}
          />

          {commentsEnabled && (
            <>
              <hr className="border-gray-300 my-6" />

              <Field label="Comments (optional)" error={form.formState.errors.comments?.message}>
                <p className="text-gray-600 text-sm">
                  Add comments that would help vendors better meet your needs i.e. additional
                  fitment details, refurbished options requested, etc.
                </p>
                <TextArea {...form.register('comments')} disabled={!machineSelected} />
              </Field>
            </>
          )}
        </FormSection>

        <StickyBottomBar>
          {reset && <StartOverButton reset={reset} />}

          <div className="flex-grow" />

          <Action.P color="blue" type="submit">
            {submitButtonText}
          </Action.P>
        </StickyBottomBar>
      </form>
    </>
  )
}

export default PartDetailsStep
