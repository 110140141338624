import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import AddressInputField from '@/gf/components/AddressInput'
import Card from '@/gf/components/Card'
import { LoadingFormCard } from '@/gf/components/LoadingCard'
import SelectField from '@/gf/components/SelectField'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import { GraphQLError } from 'graphql'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Option } from '../../../../types'
import { useFetchQualificationQuery, useQualifyBuyerMutation } from '../../../_gen/gql'
import {
  BalancePendingApproval,
  CompleteApproveNetTerms,
  PendingApproveNetTerms,
} from '../../../components/ApproveNetTerms'
import type { AdminBillingQualifyFormReducer } from '../../../hooks/useBillingQualifyForm'
import useGqlClient from '../../../hooks/useGqlClient'

const Business = ({
  form: { fields, errors },
  updateFields,
  reset,
  validate,
  cancel,
}: AdminBillingQualifyFormReducer & { cancel: () => void }) => {
  const { orgId } = useSession()
  const [_, msgr] = useMsgs()
  const client = useGqlClient()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const next = () => navigate('/settings/qualify/review')
  const finish = () => navigate('/settings/billing')
  const [qualifyBuyer] = useQualifyBuyerMutation({ client })

  const qualificationQuery = useFetchQualificationQuery({
    variables: { orgId },
    client,
    fetchPolicy: 'no-cache',
  })

  const qualification = qualificationQuery?.data?.org?.qualification

  const submitQualify = () => {
    setSubmitting(true)

    qualifyBuyer({
      variables: {
        ...fields,
        address: {
          companyName: null,
          rdi: null,
          deliverable: null,
          firstName: fields.address.firstName,
          lastName: fields.address.lastName,
          lineOne: fields.address.lineOne,
          lineTwo: fields.address.lineTwo,
          city: fields.address.city,
          state: fields.address.state,
          country: fields.address.country,
          postalCode: fields.address.postalCode,
          point: fields.address.point ?? null,
        },
      },
    })
      .then(() => {
        reset()
        navigate('/settings/qualify/review')
      })
      .catch((e: GraphQLError) => {
        if (e.message) msgr.add(e.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(() => setSubmitting(false))
  }

  const submitApplication = () => validate('business').then(() => submitQualify())
  const businessOptions: Option[] = [
    { id: '', display: '' },
    { id: 'llc', display: 'llc' },
    { id: 'corporate', display: 'corporate' },
    { id: 'partnership', display: 'partnership' },
    { id: 'sole proprietorship', display: 'sole proprietorship' },
  ]

  return !qualificationQuery?.loading && !qualification ? (
    <Card>
      <Card.Section>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-2">
            <TextField
              label="Business Name"
              value={fields.businessName}
              setValue={(businessName) => updateFields({ businessName })}
              errorText={errors.businessName}
            />
            <SelectField
              label="Business Type"
              currentId={fields.businessType}
              required
              options={businessOptions}
              onChange={(businessType) => updateFields({ businessType })}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <TextField
              label="EIN"
              value={fields.ein}
              type="number"
              setValue={(ein) => updateFields({ ein })}
              errorText={errors.ein}
            />
            <TextField
              label="Annual Sales"
              value={fields.annualSales}
              type="number"
              setValue={(annualSales) => updateFields({ annualSales })}
              errorText={errors.annualSales}
            />
          </div>
          <TextField
            label="Website"
            value={fields.website}
            setValue={(website) => updateFields({ website })}
            errorText={errors.website}
          />
          <AddressInputField
            hideCompany
            requireFirstLastName
            address={fields.address}
            onChange={(address) => updateFields({ address })}
          />
        </div>
      </Card.Section>
      <Card.Section>
        <div className="flex gap-6 justify-end">
          <div className="flex items-center text-xs">
            By clicking Submit, you agree to our Terms of Use and
            <a
              className="pl-1 text-blue-500 underline underline-offset-2"
              href="https://www.getbalance.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
          </div>
          <Action.T onClick={cancel}>
            <span className="text-sm">Cancel</span>
          </Action.T>

          <div className="flex gap-2">
            <Action.P onClick={submitApplication} disabled={submitting} performing={submitting}>
              Submit
            </Action.P>
          </div>
        </div>
      </Card.Section>
    </Card>
  ) : (
    <>
      {qualificationQuery?.loading ? (
        <LoadingFormCard />
      ) : (
        <Card>
          <Card.Section title="">
            <div className="transform bg-white w-full border border-gray-200 flex items-center justify-center rounded-md transition-all ease-out duration-700">
              <div className="w-full relative px-2 py-2">
                <div>
                  <>
                    {qualification?.pending === false ? (
                      <>
                        {qualification?.status === 'approved' ? (
                          <CompleteApproveNetTerms />
                        ) : (
                          <PendingApproveNetTerms />
                        )}
                      </>
                    ) : (
                      <>
                        {qualification?.status === 'pending' ? (
                          <BalancePendingApproval />
                        ) : (
                          <PendingApproveNetTerms />
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="flex gap-6 justify-end pt-4">
              <>
                {qualification?.pending === false ? (
                  <Action.P onClick={finish}>Finish</Action.P>
                ) : (
                  <>
                    {qualification?.status === 'pending' ? (
                      <Action.P onClick={next}>Next</Action.P>
                    ) : (
                      <Action.P onClick={finish}>Finish</Action.P>
                    )}
                  </>
                )}
              </>
            </div>
          </Card.Section>
        </Card>
      )}
    </>
  )
}

export default Business
